<template>
  <div>
    <PrivateKnowledgeHeader />
    <div class="d-flex flex-row">
      <KnowledgeSettingsMenu
        class="menu"
        knowledge-type="private"
        :key="focusAdminKnowledge.id"
        :data="menuItems"
      />
      <div class="item mx-auto">
        <div v-for="item in flatSubItems" :key="item.title">
          <settings-item
            :item="{ title: item.title, icon: item.icon, hash: item.hash }"
            :id="`settings-item_${item.name}_${item.hash.substring(1)}`"
            :active="activeRoute === item.hash"
            :ref="item.hash"
          >
            <component :is="item.component" :knowledge="focusAdminKnowledge" />
          </settings-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SettingsItem from '../../../components/Settings/SettingsItem.vue';
import KnowledgeSettingsMenu from '../KnowledgeSettingsMenu.vue';
import PrivateKnowledgeHeader from './PrivateKnowledgeHeader.vue';

import PrivateKnowledgeLanguageSettings from './PrivateKnowledgeLanguageSettings.vue';

const HASH_FALLBACK = {
  configuration: 'language',
};

export default {
  name: 'PrivateSettings',
  components: {
    SettingsItem,
    KnowledgeSettingsMenu,
    PrivateKnowledgeHeader,
    PrivateKnowledgeLanguageSettings,
  },
  data() {
    return {
      activeRoute: null,
      menuItems: [
        {
          title: 'private-knowledge.configuration.title',
          name: 'configuration',
          subItem: [
            {
              title: 'private-knowledge.configuration.items.language.title',
              icon: ['fal', 'globe-africa'],
              name: 'configuration',
              hash: '#language',
              component: PrivateKnowledgeLanguageSettings,
            },
          ],
        },
      ],
    };
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    document.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    flatSubItems() {
      return this.menuItems.reduce((acc, item) => {
        return acc.concat(item.subItem);
      }, []);
    },
    ...mapGetters('adminModule', ['focusAdminKnowledge']),
  },
  methods: {
    focusSetting(route, immediate = false) {
      if (this.scroll) return;
      const { path, hash } = route;
      const setting = path.split('/').pop();
      this.activeRoute = hash;
      var element = this.$refs[hash];
      if (!element || !element.length) {
        if (hash) return;
        this.$router.replace({ hash: HASH_FALLBACK[setting] || '' });
        return;
      }
      var top = element[0].$el.offsetTop;
      this.focus = true;
      window.scrollTo({
        top: top - 96,
        ...(immediate ? {} : { behavior: 'smooth' }),
      });
      setTimeout(() => {
        this.focus = false;
      }, 1000);
    },
    handleScroll() {
      if (this.focus) return;
      const top = window.scrollY + 96;
      const items = this.getItemsDiv();

      let idx = items.findIndex(
        ({ offsetTop, offsetBottom }) =>
          offsetTop <= top && top <= offsetBottom,
      );

      if (idx < 0) return;

      const [, newName, newId] = items[idx].id.split('_');

      const newHash = '#' + newId;
      const { hash } = this.$route;
      if (hash === newHash) return;

      this.scroll = true;
      this.activeRoute = newHash;
      this.$router.replace({ name: `private-${newName}`, hash: newHash });
      setTimeout(() => {
        this.scroll = false;
      }, 500);
    },
    getItemsDiv() {
      const itemsEl = document.querySelectorAll(`[id^="settings-item_"]`);
      return [...itemsEl].map(({ offsetTop, id, offsetHeight }) => ({
        offsetTop,
        id,
        offsetBottom: offsetTop + offsetHeight,
      }));
    },
    ...mapActions('adminModule', ['setFocusAdminKnowledgeId']),
  },
  watch: {
    '$route.params.knowledgeId': {
      handler(newVal) {
        this.setFocusAdminKnowledgeId(newVal);
      },
      immediate: true,
    },
    $route: {
      handler(newVal) {
        this.focusSetting(newVal);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  width: 20%;
  margin-left: 60px;
  margin-top: 36px;
}

.item {
  position: absolute;
  left: 25%;
  width: 50%;
  top: 96px;
}
</style>
