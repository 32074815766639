<template>
  <div :class="`${base}`">
    <WorkflowCallout
      class="mt-2"
      :title="$t(`${pre}.title`)"
      :description="$t(`${pre}.description`)"
    />
  </div>
</template>
<script>
import WorkflowCallout from '@/components/Workflow/WorkflowCallout.vue';

export default {
  name: 'workflow-init-session',
  components: { WorkflowCallout },
  props: {
    workflowAction: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    if (!this.workflowAction._id) {
      this.$emit('save');
    }
  },
  data: () => ({
    base: 'workflow-init-session',
    pre: 'knowledge.diagnostic-builder.side-bar.workflow.inputs.init-session',
  }),
};
</script>
<style lang="scss"></style>
