var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{staticStyle:{"width":"100%","padding":"0px 10px","background-color":"#f8f9fe"},attrs:{"data":_vm.tableData,"default-sort":{ prop: 'date', order: 'descending' },"cell-style":{
    'background-color': '#f8f9fe',
    width: '100%',
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  },"header-cell-style":{ 'background-color': '#f8f9fe', 'border-top': 'none' }}},[_c('el-table-column',{attrs:{"prop":"date","label":_vm.$t('analytics-download.date'),"width":"120","sortable":""}}),_c('el-table-column',{attrs:{"prop":"type","label":_vm.$t('analytics-download.type'),"width":"120"}}),_c('el-table-column',{attrs:{"prop":"name","label":_vm.$t('analytics-download.name'),"width":"230"}}),_c('el-table-column',{attrs:{"prop":"address","label":_vm.$t('analytics-download.address')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('a',{staticClass:"address-link",attrs:{"href":scope.row.address}},[_vm._v(_vm._s(scope.row.address))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('analytics-download.action'),"width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{attrs:{"content":_vm.$t('analytics-download.download')}},[_c('div',{staticClass:"download-icon-wrapper"},[_c('font-awesome-icon',{staticClass:"download-icon",attrs:{"icon":['fal', 'arrow-to-bottom']},on:{"click":function($event){return _vm.handleDownload(scope.row)}}})],1)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }