var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preference"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"info-group"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.preference.title))]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.preference.description)+" ")])])]),_c('div',{staticClass:"switch"},[(_vm.preference.hasRule && _vm.optionSwitch)?_c('div',{staticClass:"rule-row"},[_c('div',{class:['rule-input', { 'is-error': this.$v.input.$invalid }]},[_c('el-input',{staticClass:"select-value",attrs:{"type":"number","placeholder":"28"},on:{"blur":_vm.setRuleValue},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.setRuleValue.apply(null, arguments)}},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}},[(_vm.isRange)?_c('template',{slot:"append"},[_vm._v(_vm._s(_vm.timeRange))]):_vm._e()],2)],1)]):(_vm.preference.isColor)?_c('div',[_c('ColorInput',{attrs:{"element-value-display":_vm.color,"layout":"col"},on:{"new-element":_vm.updatePreference}})],1):(_vm.preference.isImage)?_c('div',[_c('SettingsImage',{attrs:{"value":_vm.image,"height":_vm.dimensions.height,"width":_vm.dimensions.width,"type":this.preference.assetType,"hide-description":true,"layout":"vertical"},on:{"delete":_vm.deleteImagePreference,"update":_vm.updatePreference}})],1):(_vm.preference.pluginIntegration)?_c('div',[_c('el-tooltip',{attrs:{"content":_vm.$t(
              'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.integrations.disable',
              {
                name: _vm.$t(
                  `settings-layout.menu-data.plugins.${_vm.pluginTranslationKey}.title`,
                ),
              },
            ),"effect":"light","disabled":!_vm.preference.disabled,"visible-arrow":false,"placement":"top-start"}},[(_vm.pluginSwitch !== null)?_c('div',[_c('el-switch',{attrs:{"disabled":_vm.preference.disabled},on:{"change":_vm.updatePluginIntegration},model:{value:(_vm.pluginSwitch),callback:function ($$v) {_vm.pluginSwitch=$$v},expression:"pluginSwitch"}})],1):_vm._e(),_c('div')])],1):_c('el-switch',{model:{value:(_vm.optionSwitch),callback:function ($$v) {_vm.optionSwitch=$$v},expression:"optionSwitch"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }