<template>
  <div class="mt-2">
    <el-select
      size="mini"
      v-model="selectValue"
      :placeholder="$t(element.emptyPlaceholder)"
      @change="newValue"
    >
      <el-option
        v-for="choice in element.choices"
        :key="choice.value"
        :command="choice"
        :style="
          element.styleInjection ? element.styleInjection(choice.label) : ''
        "
        :label="choice.trad ? $t(choice.label) : choice.label"
        :value="choice.value"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'select-input',
  data() {
    return {
      selectValue: '',
    };
  },
  props: {
    element: Object,
    elementValueDisplay: String,
    isEmpty: Boolean,
  },
  mounted() {
    if (!this.isEmpty) this.selectValue = this.elementValueDisplay;
  },
  methods: {
    newValue() {
      this.$emit('new-element', this.selectValue);
    },
  },
  watch: {
    elementValueDisplay() {
      if (!this.isEmpty) return (this.selectValue = this.elementValueDisplay);
      this.selectValue = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 40%;
}
:deep() .el-select .el-input .el-input__inner {
  height: 34px;
  line-height: 34px;
}
</style>
