<template>
  <div :class="base">
    <div
      :class="[`${base}__section`, 'mb-2']"
      v-for="(section, idx) in sections"
      :key="idx"
    >
      <component
        v-for="el in section"
        :class="[section.length === 1 ? 'col-12' : 'col-6']"
        :is="el.type"
        :key="el.key"
        v-bind="el"
        :value="inputs[el.key]"
        @change="($evt) => setPayloadKey(el, $evt)"
      />
    </div>
  </div>
</template>
<script>
import WorkflowInput from 'components/Workflow/WorkflowInput.vue';
import WorkflowSelect from 'components/Workflow/WorkflowSelect.vue';

const pre =
  'knowledge.diagnostic-builder.side-bar.workflow.inputs.set-context-from-input';

export default {
  name: 'workflow-set-context-from-input',
  components: {
    WorkflowInput,
    WorkflowSelect,
  },
  props: {
    workflowAction: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    payload: {
      get() {
        return this.workflowAction.payload || {};
      },
      set(value) {
        this.$emit('change', { ...this.workflowAction, payload: value });
      },
    },
  },
  mounted() {
    this.syncInputs(this.payload);
  },
  data() {
    return {
      base: 'workflow-set-context-from-input',
      sections: [
        [
          {
            title: this.$t(`${pre}.description.title`),
            info: this.$t(`${pre}.description.description`),
            placeholder: this.$t(`${pre}.description.placeholder`),
            key: 'description',
            icon: 'plug',
            type: 'WorkflowInput',
          },
        ],
        [
          {
            title: this.$t(`${pre}.targetKey.title`),
            placeholder: this.$t(`${pre}.targetKey.placeholder`),
            key: 'targetKey',
            icon: 'plug',
            disabled: false,
            type: 'WorkflowInput',
          },
          {
            title: this.$t(`${pre}.inputType.title`),
            placeholder: this.$t(`${pre}.inputType.placeholder`),
            key: 'inputType',
            icon: 'plug',
            disabled: true,
            options: [
              { label: 'Text', value: 'text' },
              { label: 'Number', value: 'number' },
            ],
            type: 'WorkflowSelect',
          },
        ],
      ],
      inputs: {
        description: null,
        targetKey: null,
        inputType: null,
      },
    };
  },
  methods: {
    setPayloadKey(el, inputValue) {
      this.payload = Object.assign(this.payload, {
        [el.key]: inputValue,
      });
    },
    syncInputs(payload) {
      this.inputs = payload;
      // TMP : while there is not other types
      this.inputs.inputType = 'text';
    },
  },
  watch: {
    payload() {
      this.syncInputs(this.payload);
    },
  },
};
</script>
<style lang="scss" scoped>
.workflow-set-context-from-input {
  &__section {
    display: flex;
    flex-direction: row;
  }
}
</style>
