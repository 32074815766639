<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8 d-flex align-items-center">
      <div class="col-lg-7 col-md-10">
        <h1 class="display-2">Feedbacks</h1>
        <p class="text-white mt-0 mb-3">Bienvenue dans le centre d'édition des notifications</p>
      </div>
    </base-header>
    <div class="container col-12" style="margin-top:-3%">
      <!-- Collapsible -->
      <div class="row align-items-top">
        <div class="col-xl-12 order-xl-1">
          <br>
          <br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "feedbacks",
  display: "Feedbacks",
  order: 15,
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    
  }
};
</script>

<style scoped>

</style>