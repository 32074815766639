<template>
  <md-selfcare>
    <div v-if="mounted" class="not-found">Script not found</div>
  </md-selfcare>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SelfcareEmbedded',
  data() {
    return {
      mounted: false,
    };
  },
  computed: {
    scriptUrl() {
      return `https://${this.focusCompanyPublicConfig.hostname}/embedded/md-selfcare.umd.js`;
    },

    ...mapGetters('publicKnowledgeModule', [
      'focusCompanyPublicConfig',
      'eventEmitterPlugins',
    ]),
  },
  methods: {
    updatePath(e) {
      // replace current location search query path with e.detail.route
      window.history.replaceState(
        null,
        null,
        window.location.pathname + '?path=' + e.detail.route,
      );
    },
    handleEventListener({ id, event, data }, e) {
      // I leave this console.log() for better observability
      console.log({ id, event, data, e });
      const h = this.$createElement;
      this.$notify({
        title: 'Nouvel événement intercepté',
        message: h('ul', null, [
          h('li', null, `Plugin Id: ${id}`),
          h('li', null, `Evenement: ${event}`),
          h('li', null, `Data voulue : ${JSON.stringify(data)}`),
          h('li', null, `Data reçue : ${JSON.stringify(e.detail)}`),
        ]),
        type: 'info',
        duration: 0,
        closable: true,
        customClass: 'custom-notification custom-notification-info',
      });
    },
    ...mapActions('publicKnowledgeModule', [
      'getFocusCompanyPublicConfigByKnowledgeId',
    ]),
  },
  async created() {
    const { knowledgeId } = this.$route.params;

    await this.getFocusCompanyPublicConfigByKnowledgeId(knowledgeId);

    const scriptEl = document.createElement('script');
    scriptEl.setAttribute('src', this.scriptUrl);
    document.body.append(scriptEl);

    window.addEventListener('MdSelfcareUpdated', this.updatePath);

    this.eventEmitterPlugins.forEach((plugin) => {
      window.addEventListener(`${plugin.event}`, (e) => {
        this.handleEventListener(plugin, e);
      });
    });
  },
  mounted() {
    const l = this.$i18n.locale;
    window.addEventListener('MdSelfcareLoaded', (e) => {
      // 'init' is a md-selfcare function that init accessKey and locale in the store
      e.detail.init({
        accessKey: this.focusCompanyPublicConfig.embeddedAccessKey,
        // No worries with en-en, it will be replaced with en-us or en-gb by 'guessLanguage'
        locale: `${l}-${l}`,
      });
    });

    setTimeout(() => {
      this.mounted = true;
    }, 2000);
  },
  beforeDestroy() {
    const scriptEl = document.querySelector(`script[src="${this.scriptUrl}"]`);
    if (scriptEl) {
      scriptEl.remove();
    }
    window.removeEventListener('MdSelfcareUpdated', this.updatePath);
    this.eventEmitterPlugins.forEach((plugin) => {
      window.removeEventListener(`${plugin.event}`);
    });
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 2rem;
  font-weight: bold;
}
</style>
