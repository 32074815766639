var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content bg-mesh-gradient",attrs:{"id":"main"}},[_c('div',{staticClass:"body-content"},[_c('div',{staticClass:"header pt-5 pb-4 pt-lg-5 pb-lg-4"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"header-body text-center mb-8"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-xl-5 col-lg-6 col-md-8 col-sm-9"},[(_vm.$router.currentRoute.path === '/login')?_c('h1',{staticClass:"text-white title"},[_vm._v(" "+_vm._s(_vm.$t('landing.welcome.login'))+" ")]):(
                  ['/register', '/user/invite/'].includes(
                    _vm.$router.currentRoute.path,
                  )
                )?_c('h1',{staticClass:"text-white title"},[_vm._v(" "+_vm._s(_vm.$t('landing.welcome.register'))+" ")]):(
                  ['/permissions/validated', 'error'].includes(
                    _vm.$router.currentRoute.path,
                  )
                )?_c('div',{staticClass:"row mb-6"}):_c('h1',{staticClass:"text-white title"},[_vm._v(" "+_vm._s(_vm.errorFromRegister ? _vm.$t('landing.welcome.register') : _vm.$t('landing.welcome.reset'))+" ")])])])])])]),_vm._m(0),_c('div',{staticClass:"container mt-3 pb-0"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-xl-5 col-lg-6 col-md-7 col-sm-9"},[_c('slide-y-up-transition',{attrs:{"mode":"out-in","origin":"center top"}},[_c('router-view')],1)],1)])])]),_c('footer',{staticClass:"footer",staticStyle:{"line-height":"1.15 !important"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center justify-content-between py-2 mt-6 mt-md-0"},[_c('div',{staticClass:"col-sm-12 col-md-auto text-center"},[_c('div',{staticClass:"copyright nav-footer text-white"},[_c('p',[_vm._v("© "+_vm._s(_vm.year)+" "+_vm._s(_vm.$t('landing.footer.copyright')))])])]),_c('div',{staticClass:"col-sm-12 col-md-auto justify-content-center d-flex"},[_c('ul',{staticClass:"nav nav-footer justify-content-lg-end d-md-flex"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link text-white",attrs:{"href":"https://www.mayday.fr","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('landing.footer.website')))])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link text-white",attrs:{"href":"https://www.mayday.fr/legal/politique-de-confidentialite","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('landing.footer.privacy-policy')))])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link text-white",attrs:{"href":"https://www.mayday.fr/legal/conditions-generales","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('landing.footer.tcu')))])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container mt--8 pb-0"},[_c('div',{staticClass:"row justify-content-center"},[_c('img',{staticClass:"logo",attrs:{"src":require("assets/logos/mayday.svg"),"alt":"Mayday logo"}})])])
}]

export { render, staticRenderFns }