<template>
  <div class="d-flex flex-row align-items-center">
    <el-slider
      v-model="sliderValue"
      :max="40"
      :step="1"
      class="slider"
      :show-tooltip="false"
      @input="newValue"
    ></el-slider>
    <div class="placeholder-value ml-2">{{ elementValueDisplay }}</div>
  </div>
</template>

<script>
export default {
  name: 'SliderInput',
  data() {
    return {
      sliderValue: parseInt(this.elementValueDisplay.replace('px', '')),
      initValue: null,
    };
  },
  props: {
    element: Object,
    elementValueDisplay: String,
    isEmpty: Boolean,
  },

  methods: {
    newValue(e) {
      if (!this.initValue || this.sliderValue === this.initValue)
        return (this.initValue = parseInt(
          this.elementValueDisplay.replace('px', ''),
        ));
      this.$emit('new-element', `${e}px`);
    },
  },
  watch: {
    elementValueDisplay() {
      this.sliderValue = parseInt(this.elementValueDisplay.replace('px', ''));
    },
  },
};
</script>

<style lang="scss" scoped>
.slider {
  width: 70%;
  margin-left: 4px;
  margin-right: 4px;
}
.placeholder-value {
  font-size: 12px;
  color: $grey-6-mayday;
}
:deep() .el-slider__button-wrapper {
  z-index: 0 !important;
}
</style>
