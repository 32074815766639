<template>
  <div>
    <div class="d-flex flex-row align-items-center item-header">
      <div :class="['icon-wrapper', { active: active }]">
        <font-awesome-icon :icon="item.icon" class="item-icon" />
      </div>

      <span class="item-title">{{ $t(item.title) }}</span>
    </div>

    <div class="item">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettingsItem',
  props: {
    item: {
      type: Object,
      default: () => ({ title: 'TITLE', hash: '#HASH', icon: ['fas', 'cog'] }),
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  background-color: white;
  border: 1px $grey-4-mayday solid;
  border-radius: 4px;
  padding: 14px;
  margin-bottom: 25px;
}

.item-header {
  margin-bottom: 10px;
}

.icon-wrapper {
  width: 28px;
  height: 28px;
  background-color: $grey-2-mayday;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.active {
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
}

.item-icon {
  width: 14px;
  height: 14px;
}

.item-title {
  font-family: Lato;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  text-align: left;
  font-weight: 600;
}
</style>
