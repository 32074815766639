<template>
  <el-checkbox-group v-model="checkedPermissions" class="checkboxes-container">
    <el-checkbox
      v-for="permission in permissions"
      :key="permission"
      :label="permission"
      :disabled="!entityPermissions.includes(permission)"
      size="mini"
      class="checkbox-button"
      @change="$emit('change', checkedPermissions)"
      >{{ computeTranslation(permission) }}
    </el-checkbox>
  </el-checkbox-group>
</template>

<script>
export default {
  name: 'MaydayApiPermissionsCheckboxes',
  props: {
    appPermissions: {
      type: Array,
      default: () => [],
    },
    entityPermissions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      checkedPermissions: [],
      permissions: ['create', 'read', 'update', 'delete'],
    };
  },
  methods: {
    computeTranslation(name) {
      return this.$t(`settings.integration.mayday-api.permissions.${name}`);
    },
  },
  watch: {
    appPermissions(newVal) {
      this.checkedPermissions = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
.checkboxes-container {
  display: flex;
  width: 100%;
  max-width: 270px;
  justify-content: space-between;
  align-items: flex-start;
}
.checkbox-button {
  display: flex;
  width: 70px;
  flex-direction: column-reverse;
  align-items: center;
  gap: 4px;
  margin: 0px;
  margin-right: 12px;
  :deep() .el-checkbox__label {
    color: black;
    padding: 0px;
  }
  &.is-checked {
    :deep() .el-checkbox__inner {
      border-radius: 2px;
      background-color: $blue-mayday;
      box-sizing: border-box;
      box-shadow: 0 0 0 2px #f3f5f6 inset;
      border: 1px solid $blue-mayday;
      &:after {
        display: none;
      }
    }
  }
}
</style>
