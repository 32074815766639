<template>
  <!-- <div class="pt-4">
    <div>
      <div class="row mx-3">
        <div class="col-2">
          <NotificationCenterMenu
            :sections="sections"
            :target-groups="currentFilters.targetGroups"
            @click="updateSelectedSection"
            @filters-update="updateCurrentFilters"
          />
        </div>

        <div class="col-7 notification-center-section mb-4 pt-2">
          <NotificationCenterMain
            :notifications="displayedNotifications"
            :target-groups="currentFilters.targetGroups"
            :current-page="currentFilters.currentPage"
            :total="total"
            @delete-notification="deleteNotification"
            @update-notification="updateNotification"
            @select-details="selectedNotification = $event"
            @filters-update="updateCurrentFilters"
            v-loading="isLoading"
          />
        </div>

        <div class="col-3 mt-2" v-if="selectedNotification !== null">
          <NotificationCenterDetails
            :data="selectedNotification"
            @close="selectedNotification = null"
          />
        </div>
      </div>
    </div>

    <ModalEvents
      modal-name="CreateNotificationModal"
      :uid="createNotificationUniqueKey"
      @choice="addNotification"
      @close="modals.addNotification = false"
    />

    <MaydayFloatingButton
      @click="openNotificationModal"
      :state="modals.addNotification"
    />
  </div> -->
  <div
    v-loading="true"
    class="d-flex h-100 w-100 justify-content-center align-items-center full-screen"
  ></div>
</template>

<script>
// import { mapActions, mapGetters, mapState } from 'vuex';
// import NotificationCenterMenu from './Menu/NotificationCenterMenu';
// import NotificationCenterDetails from './Details/NotificationCenterDetails';
// import NotificationCenterMain from './Main/NotificationCenterMain';
// import MaydayFloatingButton from 'components/MaydayFloatingButton';
// import ModalEvents from '@/components/Modals/ModalEvents';

import { required } from 'vuelidate/lib/validators';

export default {
  name: 'NotificationCenter',
  components: {
    // NotificationCenterMenu,
    // NotificationCenterDetails,
    // NotificationCenterMain,
    // ModalEvents,
    // MaydayFloatingButton,
  },
  data() {
    return {
      isLoading: false,
      displayedNotifications: [],
      total: 0,
      currentFilters: {
        targetGroups: [],
        currentPage: 1,
      },
      modals: {
        addNotification: false,
      },
      selectedNotification: null,
      sections: [
        {
          title: 'Notifications',
          icon: ['fad', 'paper-plane'],
          description: this.$t('notification-center.notif-page-subtitle'),
          data: [],
          selected: true,
        },
      ],
    };
  },
  mounted() {
    window.location.href = `${window.location.origin}/notifications`;

    // this.isLoading = true;
    // window.addEventListener(
    //   'md-createdAgentNotificationsUpdate',
    //   this.getNotificationsHandler,
    // );
    // await this.getCompanyGroups();
    // const isFromNotificationCenter = true;

    // window.dispatchEvent(
    //   new CustomEvent('md-updateFilters', {
    //     detail: {
    //       field: 'targetGroups',
    //       value: this.groups,
    //       isFromNotificationCenter,
    //     },
    //   }),
    // );
  },
  destroyed() {
    // window.removeEventListener(
    //   'md-createdAgentNotificationsUpdate',
    //   this.getNotificationsHandler,
    // );
  },
  computed: {
    // createNotificationUniqueKey() {
    //   return `${this.$options.name}-modal`;
    // },
    // ...mapState(['companyId', 'accessToken']),
    // ...mapGetters('adminModule', ['groups']),
  },
  validations: {
    new_title: {
      required,
    },
    new_message: {
      required,
    },
  },
  methods: {
    getNotificationsHandler(e) {
      const { results, total, filters } = e.detail;
      this.displayedNotifications = results;
      this.total = total;
      this.currentFilters = filters;
      this.isLoading = false;
    },
    async updateCurrentFilters({ field, value }) {
      this.isLoading = true;
      window.dispatchEvent(
        new CustomEvent('md-updateFilters', {
          detail: {
            field,
            value,
            isFromNotificationCenter: true,
          },
        }),
      );
    },
    updateNotification({ notificationId, metadata }) {
      window.dispatchEvent(
        new CustomEvent('md-updateAgentNotification', {
          detail: { notificationId, metadata },
        }),
      );
    },
    deleteNotification(notificationId) {
      window.dispatchEvent(
        new CustomEvent('md-deleteAgentNotification', {
          detail: notificationId,
        }),
      );
    },
    addNotification({ notification }) {
      if (notification) {
        notification.companyId = this.companyId;
        window.dispatchEvent(
          new CustomEvent('md-addAgentNotification', {
            detail: {
              ...notification,
            },
          }),
        );
      }
      this.modals.addNotification = false;
    },
    updateSelectedSection(event) {
      this.sections.forEach((elem) => {
        if (elem.title == event) {
          elem.selected = true;
        } else elem.selected = false;
      });
    },
    openNotificationModal() {
      const payload = {
        component: 'CreateNotificationModal',
        uid: this.createNotificationUniqueKey,
        props: {
          open: true,
        },
      };
      this.openModal(payload);
      this.modals.addNotification = true;
    },
    // ...mapActions('adminModule', ['getCompanyGroups']),
    // ...mapActions('modalsModule', ['openModal']),
  },
};
</script>

<style lang="scss" scoped>
.full-screen {
  height: 100vh !important;
}
</style>
