<template>
  <div class="filter-field-container">
    <Title
      :name="filter.label"
      :field="filter.field"
      :value="value"
      v-if="!inhibitTitle"
      @reset="$emit('reset', $event)"
      @unselect-all="$emit('unselect-all')"
    />
    <RemoteSearch
      class="remote-search"
      v-if="filter.isRemote"
      :value="remoteValue"
      :condition="conditionValue"
      @change-additional="$emit('change-additional', $event)"
    />
    <slot></slot>
  </div>
</template>

<script>
import RemoteSearch from './RemoteSearch';
import Title from './Title';

export default {
  name: 'base-filter',

  components: { RemoteSearch, Title },

  props: {
    filter: {
      type: Object,
      required: true,
    },
    value: {
      required: false,
    },
    conditionValue: {
      type: String,
      required: false,
    },
    remoteValue: {
      type: String,
      required: false,
    },
    inhibitTitle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-field-container {
  margin: 8px 0;
}
.remote-search {
  margin-bottom: 8px;
}
</style>
