<template>
  <div :class="base">
    <!-- 1. API SETUP -->
    <div :class="`${base}__divider`">
      <span :class="`${base}__divider__title`">{{
        $t(`${pre}.dividers.resource`)
      }}</span>
      <span :class="`${base}__divider__line`"></span>
    </div>
    <div :class="`${base}__section`">
      <WorkflowSelect
        class="col-6"
        icon="plug"
        :title="$t(`${pre}.resource.title`)"
        :placeholder="$t(`${pre}.resource.placeholder`)"
        :footer-text="$t(categoryFooter)"
        :value="inputs.resourceId"
        :options="resources"
        @change="setInputValue('resourceId', $event)"
      />
      <WorkflowSelect
        class="col-6"
        icon="plug"
        :title="$t(`${pre}.resource-function.title`)"
        :placeholder="$t(`${pre}.resource-function.placeholder`)"
        :value="inputs.resourceFunctionId"
        :disabled="resourceFunctions.length == 0"
        :options="resourceFunctions"
        filterable
        @change="setInputValue('resourceFunctionId', $event)"
      />
    </div>
    <div :class="`${base}__section__callout`" v-if="isDevelopmentResource">
      <WorkflowCallout
        :title="$t(`${pre}.warning-callout.title`)"
        :description="$t(`${pre}.warning-callout.description`)"
        type="danger"
      />
    </div>
    <div
      :class="`${base}__section`"
      v-for="(section, idx) in parametersSection"
      :key="idx"
    >
      <component
        :class="[`${base}__section__item`, 'col-6']"
        :is="section[0].type"
        :key="section[0].key"
        v-bind="section[0]"
      />
      <component
        :class="[`${base}__section__item`, 'col-6']"
        :is="section[1].type"
        :key="section[1].key"
        v-bind="section[1]"
        :value="inputs.parameters[section[1].key]"
        @change="($evt) => setParametersValue(section[1].key, $evt)"
      />
    </div>

    <!-- 2. TRANSFORM -->
    <div :class="`${base}__divider mt-4`">
      <span :class="`${base}__divider__title`">{{
        $t(`${pre}.dividers.transform`)
      }}</span>
      <span :class="`${base}__divider__line`"></span>
    </div>
    <div
      :class="`${base}__section`"
      v-for="(el, idx) in inputs.transform"
      :key="el.uuid"
    >
      <component
        :class="[`${base}__section__item`, 'mb-2']"
        v-for="field in section"
        :is="field.type"
        :key="field.key"
        v-bind="field"
        :value="el[field.key]"
        @change="($evt) => setTransformItem(idx, field.key, $evt)"
      />
      <font-awesome-icon
        :class="[`${base}__section__delete`]"
        :icon="['fal', 'times']"
        @click="removeTransformItem(idx)"
      />
    </div>
    <div :class="`${base}__add`">
      <base-button
        size="sm"
        type="primary"
        :outline="true"
        @click="addTransformItem()"
        >{{ $t(`${pre}.add`) }}</base-button
      >
    </div>

    <!-- 3. CONDITIONAL TRIGGER -->
    <div :class="`${base}__divider mt-4`">
      <span :class="`${base}__divider__title`">{{
        $t(`${pre}.dividers.trigger`)
      }}</span>
      <span :class="`${base}__divider__line`"></span>
    </div>
    <WorkflowLogic
      v-if="inputs.trigger.id"
      :class="`${base}__section__item mb-4`"
      :logicRule="inputs.trigger"
      @change="setInputValue('trigger', $event)"
    />
    <WorkflowCallout
      v-else
      :class="`${base}__section__item mb-4`"
      @click="initTrigger()"
      :title="$t(`${pre}.trigger.title`)"
      :description="$t(`${pre}.trigger.description`)"
      :cta="$t(`${pre}.trigger.cta`)"
    />
  </div>
</template>
<script>
import { v4 as uuid } from 'uuid';

import WorkflowInput from 'components/Workflow/WorkflowInput.vue';
import WorkflowSelect from 'components/Workflow/WorkflowSelect.vue';
import WorkflowCallout from 'components/Workflow/WorkflowCallout.vue';
import WorkflowLogic from 'components/Workflow/WorkflowLogic/WorkflowLogic.vue';

import { mapActions } from 'vuex';
import { randomString } from '@/components/stringUtils';

const pre =
  'knowledge.diagnostic-builder.side-bar.workflow.inputs.set-context-from-resource-function';

export default {
  name: 'workflow-set-context-from-resource-function',
  components: {
    WorkflowInput,
    WorkflowSelect,
    WorkflowCallout,
    WorkflowLogic,
  },
  props: {
    workflowAction: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    payload: {
      get() {
        return this.workflowAction.payload || {};
      },
      set(value) {
        this.$emit('change', { ...this.workflowAction, payload: value });
      },
    },
    resources() {
      if (!this.companyResources) return [];
      return this.companyResources.map((el) => ({
        label: el.title,
        value: el.id,
      }));
    },
    resource() {
      if (!this.inputs.resourceId) return null;
      return this.companyResources.find((r) => r.id === this.inputs.resourceId);
    },
    isDevelopmentResource() {
      if (!this.resource) return false;
      return this.resource.environment.development;
    },
    resourceFunctions() {
      if (!this.resource) return [];
      return this.resource.functions.map((el) => ({
        label: el.title,
        value: el.id,
      }));
    },
    resourceFunction() {
      if (!this.resource || !this.inputs.resourceFunctionId) return null;
      return this.resource.functions.find(
        (f) => f.id === this.inputs.resourceFunctionId,
      );
    },
    parametersSection() {
      try {
        if (!this.resourceFunction) return [];
        const parameters = this.resourceFunction.parameters;
        const prefix = `${pre}.resource-function-parameters`;
        return parameters.map((val) => [
          {
            title: this.$t(`${prefix}.parameter.title`),
            placeholder: val.label,
            key: val.key,
            value: val.label,
            disabled: true,
            icon: 'plug',
            type: 'WorkflowInput',
          },
          {
            title: this.$t(`${prefix}.value.title`),
            placeholder: this.$t(`${prefix}.value.placeholder`),
            key: val.key,
            icon: 'plug',
            type: 'WorkflowInput',
          },
        ]);
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    showTransformSection() {
      return this.inputs.resourceId && this.inputs.resourceFunctionId;
    },
  },
  async mounted() {
    this.syncInputs(this.payload);
    this.companyResources = await this.getCompanyResources();
    if (this.payload.resourceFunctionId) {
      this.resolveResourceFromFunctionId(this.payload.resourceFunctionId);
    }
  },
  data() {
    return {
      base: 'workflow-set-context-from-resource-function',
      companyResources: null,
      selectedResourceId: null,
      pre,
      section: [
        {
          title: this.$t(`${pre}.key.title`),
          placeholder: this.$t(`${pre}.key.placeholder`),
          key: 'key',
          icon: 'plug',
          type: 'WorkflowInput',
        },
        {
          title: this.$t(`${pre}.value.title`),
          placeholder: this.$t(`${pre}.value.placeholder`),
          key: 'value',
          icon: 'plug',
          type: 'WorkflowInput',
        },
      ],
      inputs: {
        transform: [],
        resourceId: null,
        resourceFunctionId: null,
        parameters: {},
        trigger: {},
      },
    };
  },
  methods: {
    // helpers
    addTransformItem() {
      this.inputs.transform.push({ key: null, value: null, uuid: uuid() });
    },
    removeTransformItem(idx) {
      this.inputs.transform.splice(idx, 1);
      this.syncPayload();
    },
    setTransformItem(idx, key, value) {
      this.inputs.transform[idx][key] = value;
      this.syncPayload();
    },
    setInputValue(key, value) {
      if (key === 'trigger' && value.rules && !value.rules.length) {
        return this.setInputValue('trigger', {});
      }
      this.inputs[key] = value;
      if (key === 'resourceId') {
        return this.setInputValue('resourceFunctionId', null);
      }
      if (key === 'resourceFunctionId') {
        return this.setInputValue('parameters', {});
      }
      this.syncPayload();
    },
    setParametersValue(key, value) {
      if (value) {
        this.inputs.parameters[key] = value;
      } else {
        delete this.inputs.parameters[key];
      }
      this.syncPayload();
    },
    syncPayload() {
      // eslint-disable-next-line no-unused-vars
      const transform = [...this.inputs.transform].map(({ uuid, ...el }) => el);
      this.payload = Object.assign(this.payload, { ...this.inputs, transform });
    },
    syncInputs(payload) {
      if (!payload.transform) {
        this.addTransformItem();
      } else {
        Object.assign(this.inputs, payload);
        this.inputs.transform = payload.transform.map((el) => ({
          ...el,
          uuid: uuid(),
        }));
      }
    },
    initTrigger() {
      this.setInputValue('trigger', {
        id: randomString(8),
        logic: 'AND',
        rules: [{}],
      });
    },
    resolveResourceFromFunctionId(id) {
      const func = this.companyResources
        .flatMap((el) => el.functions.map((f) => ({ ...f, resourceId: el.id })))
        .find((fn) => fn.id === id);
      if (func) {
        // this.syncInputs is not called here to avoid
        // deleting inputs.resourceFunctionId
        this.inputs.resourceId = func.resourceId;
        this.syncPayload();
      }
    },
    ...mapActions('knowledgeModule', ['getCompanyResources']),
  },
  watch: {
    payload() {
      this.syncInputs(this.payload);
    },
  },
};
</script>
<style lang="scss" scoped>
.workflow-set-context-from-resource-function {
  &__section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    &__item {
      flex: 12;
    }
    &__callout {
      padding: 0 4px;
      margin-bottom: 8px;
    }
    &__delete {
      flex: 1;
      margin-top: 16px;
      cursor: pointer;
      color: $grey-5-mayday;
      &:hover {
        color: $blue-mayday;
      }
    }
  }
  &__add {
    margin: 4px 4px;
  }
  &__divider {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;
    &__title {
      padding: 0 4px;
      width: fit-content;
      white-space: nowrap;
      font-weight: bold;
      color: $grey-8-mayday;
    }
    &__line {
      margin: 0 4px;
      width: 100%;
      height: 1px;
      border: 1px solid $grey-3-mayday;
    }
  }
}
</style>
