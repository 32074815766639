<template>
  <div class="preference">
    <div class="header">
      <div class="info-group">
        <div class="info">
          <div class="title">{{ preference.title }}</div>
          <div class="description">
            {{ preference.description }}
          </div>
        </div>
      </div>
      <div class="switch">
        <div v-if="preference.hasRule && optionSwitch" class="rule-row">
          <div :class="['rule-input', { 'is-error': this.$v.input.$invalid }]">
            <el-input
              type="number"
              class="select-value"
              placeholder="28"
              v-model="input"
              @keyup.enter.native="setRuleValue"
              @blur="setRuleValue"
            >
              <template v-if="isRange" slot="append">{{ timeRange }}</template>
            </el-input>
          </div>
        </div>
        <div v-else-if="preference.isColor">
          <ColorInput
            :element-value-display="color"
            layout="col"
            @new-element="updatePreference"
          />
        </div>
        <div v-else-if="preference.isImage">
          <SettingsImage
            :value="image"
            :height="dimensions.height"
            :width="dimensions.width"
            :type="this.preference.assetType"
            @delete="deleteImagePreference"
            @update="updatePreference"
            :hide-description="true"
            layout="vertical"
          />
        </div>
        <div v-else-if="preference.pluginIntegration">
          <el-tooltip
            :content="
              $t(
                'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.integrations.disable',
                {
                  name: $t(
                    `settings-layout.menu-data.plugins.${pluginTranslationKey}.title`,
                  ),
                },
              )
            "
            effect="light"
            :disabled="!preference.disabled"
            :visible-arrow="false"
            placement="top-start"
          >
            <div v-if="pluginSwitch !== null">
              <el-switch
                :disabled="preference.disabled"
                v-model="pluginSwitch"
                @change="updatePluginIntegration"
              />
            </div>
            <div></div>
          </el-tooltip>
        </div>
        <el-switch v-else v-model="optionSwitch" />
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ColorInput from 'views/Settings/PublicKnowledge/commons/ColorInput.vue';
import SettingsImage from 'components/Settings/SettingsImage.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'integration-settings-preference-option',
  components: { SettingsImage, ColorInput },
  props: {
    preference: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      input: 0,
      color: '#0081F9',
      image: null,
      pluginSwitch: false,
    };
  },
  async created() {
    this.initInput();
    if (this.preference.pluginIntegration) {
      const latestPluginEntities = await this.getPluginEntities(
        this.preference.pluginType,
      );
      this.updatePluginEntitiesState(latestPluginEntities);
      this.pluginSwitch = this.integrationStatus(
        this.preference.pluginType,
        this.preference.pluginIntegration,
      );
    }
  },
  computed: {
    isRange() {
      return !!this.preference.timeRange;
    },
    timeRange() {
      return this.preference.timeRange || '';
    },
    optionSwitch: {
      get() {
        return !!this.preference.id;
      },
      set(value) {
        if (this.preference.pluginIntegration) {
          return this.updatePluginIntegration(value);
        }
        this.$emit('switched', {
          type: this.preference.type,
          value,
          ruleValue: this.preference.defaultValue || this.preference.value,
        });
        if (this.preference.hasRule) {
          this.initInput();
        }
      },
    },
    dimensions() {
      if (
        this.preference.width &&
        parseInt(this.preference.width) &&
        this.preference.height &&
        parseInt(this.preference.height)
      ) {
        return {
          width: this.preference.width,
          height: this.preference.height,
        };
      }
      return {
        width: '256',
        height: '256',
      };
    },
    pluginTranslationKey() {
      switch (this.preference.pluginType) {
        case 'EmbeddedIframeEntity':
          return 'embedded-iframe';
        case 'TrendingContentEntity':
          return 'trending-content';
        case 'NewContentEntity':
          return 'new-content';
        case 'SmartActionEntity':
          return 'smart-actions';
        default:
          return '';
      }
    },
    ...mapGetters('adminUserDirectoryModule', [
      'integrationStatus',
      'pluginEntities',
    ]),
  },
  validations: {
    input: {
      required,
      minValue: function (value) {
        return value >= 7;
      },
    },
  },
  methods: {
    setRuleValue() {
      if (this.input !== this.preference.value) {
        if (!this.$v.input.$invalid) {
          this.$emit('update-preference-value', {
            type: this.preference.type,
            value: this.optionSwitch,
            ruleValue: this.input,
          });
          this.$message({
            message: `${this.preference.notification} ${this.input} ${this.timeRange}`,
            type: 'success',
          });
        } else {
          this.$message({
            message: `La valeur entrée doit être supérieure ou égale à 7 jours.`,
            type: 'error',
          });
          this.input = 7;
        }
      }
    },
    initInput() {
      if (this.preference.hasRule) {
        this.input = this.preference.value
          ? this.preference.value
          : this.preference.defaultValue;
      }
      if (this.preference.isColor) {
        this.color = this.preference.value
          ? this.preference.value
          : this.preference.defaultValue;
      }
      if (this.preference.isImage) {
        this.image = this.preference.value
          ? this.preference.value
          : this.preference.defaultValue;
      }
    },
    updatePreference(e) {
      this.$emit('update-preference-value', {
        type: this.preference.type,
        ruleValue: e,
      });
    },
    deleteImagePreference() {
      this.$emit('update-preference-value', {
        type: this.preference.type,
        ruleValue: null,
      });
    },
    async updatePluginIntegration(value) {
      try {
        const currentPluginEntities = this.pluginEntities(
          this.preference.pluginType,
        );
        if (!currentPluginEntities) {
          throw new Error('Existing plugin not found');
        }
        const updatedIntegrations = currentPluginEntities[0].integrations.map(
          ({ name, isActive }) =>
            name === this.preference.pluginIntegration
              ? { name, isActive: value }
              : { name, isActive },
        );
        const updatedEntities = currentPluginEntities.map((pluginEntity) => ({
          ...pluginEntity,
          integrations: updatedIntegrations,
        }));

        const res = await this.updatePluginEntities(updatedEntities);
        if (!res) {
          throw new Error('Error while updating plugin entity');
        }
      } catch (e) {
        this.pluginSwitch = !value;
      }
    },
    ...mapActions('adminUserDirectoryModule', [
      'getPluginEntities',
      'updatePluginEntities',
      'updatePluginEntitiesState',
    ]),
  },
  watch: {
    preference: {
      handler() {
        this.initInput();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.preference {
  .header {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    justify-content: space-between;
    .info-group {
      display: flex;
      align-items: center;

      .info {
        .title {
          color: black;
          font-size: 16px;
          font-weight: bold;
        }
        .description {
          color: $grey-6-mayday;
          font-size: 12px;
        }
      }
    }
  }

  .rule-input {
    :deep() .el-input__inner {
      font-size: 12px;
      width: 48px;
      height: 24px;
      padding: 0;
      padding-left: 6px;
    }
    :deep() .el-input-group__append {
      padding: 0 6px;
    }
  }
  .is-error {
    :deep() .el-input__inner {
      border: 1px solid red !important;
    }
  }
}
</style>
