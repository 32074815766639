<template>
  <div>
    <div class="card bg-secondary shadow border-0" id="reset-box">
      <div class="card-body px-lg-5 py-lg-5">
        <div class="text-center text-muted mb-4">
          <h1 class="title" v-if="!tooManyAttempts()">
            {{ $t('landing.form.reset-context-1') }}<br />
            {{ $t('landing.form.reset-context-2') }}
          </h1>
          <base-alert
            v-else-if="alert.message.length <= 0"
            ref="warning"
            type="success"
          >
            <template v-slot:text>
              {{ $t('landing.too-many-attempts') }}
            </template>
          </base-alert>

          <small v-if="!alert.message.length > 0">
            {{ $t('landing.form.reset-context-3') }}<br />
            {{ $t('landing.form.reset-context-4') }}
          </small>
        </div>

        <div v-if="alert.message.length > 0">
          <base-alert ref="alert" :type="alert.type" class="my-5">
            <template v-slot:text>
              {{ $t(alert.message) }}
              <strong>{{ model.email }}</strong>
            </template>
          </base-alert>

          <div class="text-center">
            <p>
              {{ $t('landing.reset-again-1') }}
              <button
                class="link"
                @click.prevent="alert = { message: '', type: '' }"
              >
                {{ $t('landing.reset-again-2') }}
              </button>
            </p>
          </div>
        </div>

        <div role="form" v-else>
          <base-input
            ref="email"
            :label="$t('landing.form.email')"
            class="input-group-alternative mb-0"
            :placeholder="$t('landing.form.email-placeholder')"
            addon-left-icon="fa fa-envelope"
            :valid="isValid('email')"
            :tooltip="true"
            v-model="model.email"
            @keyup.enter="enterClicked()"
          >
            <template v-slot:tooltip-content>
              <h5 class="my-2">{{ $t('landing.form.invalid-email') }}</h5>
            </template>
          </base-input>

          <div class="text-center">
            <base-button
              ref="submit"
              type="primary"
              @click.prevent="forgotPassword()"
              class="reset-button mt-5"
              :disabled="isButtonDisabled"
            >
              <div v-if="isResetLoading" role="status">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
              <span v-else>{{ $t('landing.reset-1') }}</span>
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!alert.length > 0 && !tooManyAttempts()"
      class="d-flex col-12 pt-lg-2 font-weight-bold text-white justify-content-center text-center"
    >
      <p class="my-0">
        {{ $t('landing.form.register-footer-1') }}<br />
        <router-link to="/login" class="text-underline dark-link">
          {{ $t('landing.form.register-footer-2') }}
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import BaseAlert from '@/components/BaseAlert.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseInput from '@/components/BaseInput.vue';

export default {
  name: 'resetpassword',
  components: {
    BaseAlert,
    BaseButton,
    BaseInput,
  },
  data() {
    return {
      model: {
        email: '',
      },
      isResetLoading: false,
      alert: {
        message: '',
        type: '',
      },
    };
  },
  validations: {
    model: {
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    isButtonDisabled() {
      return this.isResetLoading || this.$v.model.$invalid;
    },
  },
  methods: {
    enterClicked() {
      if (!this.isButtonDisabled) {
        this.$refs.submit.$el.children[0].click();
      }
    },
    isValid(data) {
      if (this.model[data] === '') {
        return undefined;
      }
      return !this.$v.model[data].$invalid;
    },
    forgotPassword() {
      this.isResetLoading = true;
      const email = this.model.email.toLowerCase();
      this.$services.users.resetPassword(email).then(() => {
        this.isResetLoading = false;
        this.alert = Object.assign(this.alert, {
          message: 'landing.reset-pending',
          type: 'success',
        });
      });
    },
    triggerResetPassword() {
      this.$refs.resetPassword.click();
    },
    ...mapGetters(['tooManyAttempts']),
  },
};
</script>

<style lang="scss" scoped>
#reset-box {
  margin-top: -50px;
  background-color: rgba(255, 255, 255, 0.82) !important;
  border-radius: 4px !important;
}

.title {
  line-height: 1.2 !important;
}

.link {
  border: none;
  background: none;
  color: $blue-mayday;
}

.link:hover {
  color: #00407c;
}

.reset-button {
  min-width: 150px;
}

.dark-link {
  color: #00407c;
}
</style>
