<template>
  <div>
    <div
      class="element-title d-flex justify-content-between align-items-center"
    >
      {{ $t('private-knowledge.configuration.items.language.subtitle') }}
    </div>
    <div class="element-description mb-3">
      {{ $t('private-knowledge.configuration.items.language.description') }}
    </div>

    <el-button
      class="my-2"
      size="mini"
      @click="displayModal = true"
      type="primary"
    >
      {{ $t('public-knowledge.configuration.items.language.add-language') }}
    </el-button>

    <div v-for="language in supportedLanguages" :key="language">
      <SettingsDropdown>
        <template slot="title">
          <div class="item-header d-flex flex-row align-items-center">
            <div class="d-flex align-items-center item-title-wrapper">
              <div class="item-title">
                {{ $t(`knowledge.languages.${language}`) }}
              </div>
              <el-badge
                v-if="language === defaultLanguage"
                class="ml-2 default-tag"
                type="primary"
                size="mini"
              >
                {{ $t('settings.general.kb-manage.default') }}
              </el-badge>
            </div>

            <div class="subtitles lang-tag">{{ language }}</div>
          </div>
        </template>
        <template slot="dropdown-content">
          <div class="item-body">
            <div class="element-title">
              {{
                $t(
                  'private-knowledge.configuration.items.language.body-knowledge-title',
                )
              }}
              | {{ $t(`knowledge.languages.${language}`) }}
            </div>
            <div class="element-description">
              {{
                $t(
                  'private-knowledge.configuration.items.language.body-knowledge-description',
                )
              }}
            </div>

            <div
              class="d-flex flex-row align-items-center mt-2"
              v-if="inputs[language]"
            >
              <el-input
                v-model="inputs[language].textValue"
                size="mini"
                class="text-input mr-2"
                :disabled="!inputs[language].isModifying"
              ></el-input>
              <el-button
                v-if="inputs[language].isModifying"
                size="mini"
                @click="handleSave(language)"
                type="primary"
              >
                {{
                  $t('public-knowledge.configuration.items.domain.save-button')
                }}
              </el-button>
              <el-button
                v-else
                size="mini"
                @click="inputs[language].isModifying = true"
                type="primary"
              >
                {{
                  $t(
                    'public-knowledge.configuration.items.domain.modify-button',
                  )
                }}
              </el-button>
            </div>
          </div>
        </template>
      </SettingsDropdown>
    </div>
    <NewLanguageModal
      v-if="displayModal"
      :display="displayModal"
      :existing-languages="supportedLanguages"
      @close="displayModal = false"
      @new-language="addLanguage({ id: knowledge.id, lang: $event })"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import NewLanguageModal from '@/views/Settings/PublicKnowledge/commons/NewLanguageModal';
import SettingsDropdown from '@/components/Settings/SettingsDropdown';

export default {
  name: 'private-knowledge-language-settings',
  components: {
    NewLanguageModal,
    SettingsDropdown,
  },
  props: {
    knowledge: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inputs: {},
      displayModal: false,
    };
  },
  methods: {
    async handleSave(language) {
      try {
        await this.updateKnowledgeLabel({
          id: this.knowledge.id,
          lang: language,
          newLabel: this.inputs[language].textValue,
        });
        this.inputs[language].isModifying = false;
        this.$message.success(
          this.$t('private-knowledge.configuration.items.language.updated'),
        );
      } catch (error) {
        this.$message.error(
          this.$t('private-knowledge.configuration.items.language.error'),
        );
      }
    },
    ...mapActions('adminModule', ['updateKnowledgeLabel', 'addLanguage']),
  },
  computed: {
    supportedLanguages() {
      return this.knowledge.supportedLanguages;
    },
    defaultLanguage() {
      return this.knowledge.defaultLanguage;
    },
  },
  watch: {
    knowledge: {
      immediate: true,
      handler(newKnowledge) {
        this.inputs = newKnowledge.supportedLanguages.reduce((acc, lang) => {
          acc[lang] = {
            textValue: this.knowledge.labelTranslations[lang] || '',
            isModifying: false,
          };
          return acc;
        }, {});
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}

.item-header {
  width: 100%;
  justify-content: space-between;
}

.item-title-wrapper {
  width: 50%;
}

.lang-tag {
  width: 5%;
}

.subtitles {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.item-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: capitalize;
}

.item-body {
  padding: 10px;
  background-color: $grey-1-mayday;
}
:deep() .element:first-child {
  margin-bottom: 10px;
}
.item-actions {
  width: 5%;
  cursor: pointer;
  color: $grey-5-mayday;
}

:deep() .el-collapse-item__header {
  border: none;
  height: 40px;
}

:deep() .el-collapse {
  border: none;
}

:deep() .el-collapse-item__wrap {
  border: none;
}

.default-tag {
  background-color: rgba($blue-mayday, 0.1);
  border: 1px solid $blue-mayday;
  box-sizing: border-box;
  border-radius: 2px;
  color: $blue-mayday;
  font-weight: 700;
  font-size: 10px;
  line-height: 129.8%;
  text-align: center;
  letter-spacing: 0.5px;
  padding: 2px 8px;
}

:deep() .el-popper {
  padding: 0px !important;
}

.action-icon {
  width: 14px;
  height: 14px;
}

.dropdown-item {
  cursor: pointer;
}
:deep() .dropdown-container {
  padding: 0px;
}
</style>
