<template>
  <div :class="`${base}`">
    <div
      :class="[`${base}__step-logic`, 'mb-2']"
      v-for="step in content.children"
      :key="step.id"
    >
      <span :class="`${base}__step-logic__header`">
        <span :class="`${base}__step-logic__header__label`">
          <span :class="[`${base}__step-logic__header__label__icon`, 'mr-2']">
            <img
              :class="`${base}__step-logic__header__label__icon__img`"
              src="~assets/diag-icon-v2.1.svg"
            />
          </span>
          <span :class="`${base}__step-logic__header__label__step`">
            {{ step.label }}
          </span>
        </span>
        <font-awesome-icon
          :class="`${base}__step-logic__header__trash`"
          :icon="['fad', 'trash']"
          v-if="workflowActionsByStepId[step.id]"
          @click="removeStepLogicRule(step.id)"
        />
      </span>
      <WorkflowLogic
        v-if="workflowActionsByStepId[step.id]"
        :class="`${base}__condition__logic`"
        :logicRule="workflowActionsByStepId[step.id]"
        @change="updateStepLogicRule"
      />
      <WorkflowCallout
        v-else
        class="mt-2"
        @click="createStepLogicRule(step.id)"
        :title="$t(`${pre}.fallback.title`)"
        :description="$t(`${pre}.fallback.description`)"
        :cta="$t(`${pre}.fallback.cta`)"
      />
    </div>
  </div>
</template>
<script>
import WorkflowLogic from '@/components/Workflow/WorkflowLogic/WorkflowLogic.vue';
import WorkflowCallout from '@/components/Workflow/WorkflowCallout.vue';
import { randomString } from '@/components/stringUtils';

export default {
  name: 'workflow-auto-step-selection',
  components: { WorkflowLogic, WorkflowCallout },
  props: {
    workflowAction: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    workflowActionsByStepId() {
      return this.content.children.reduce((acc, step) => {
        acc[step.id] = this.getWorkflowActionByStepId(step.id);
        return acc;
      }, {});
    },
    getContentChildById() {
      return (id) => {
        const step = this.content.children.find((c) => c.id === id);
        if (!step) return 'UNKNOWN_CONTENT';
        return step.label;
      };
    },
  },
  data: () => ({
    base: 'workflow-auto-step-selection',
    pre: 'knowledge.diagnostic-builder.side-bar.workflow.inputs.auto-step-selection',
  }),
  methods: {
    getWorkflowActionByStepId(stepId) {
      const payload = this.workflowAction.payload;
      if (!payload || !payload.conditions) return null;
      return payload.conditions.find(
        (el) => el.meta && el.meta.entityId === stepId,
      );
    },
    createStepLogicRule(stepId) {
      const newRule = {
        id: randomString(8),
        logic: 'AND',
        rules: [{}],
        meta: {
          entityId: stepId,
          entityType: 'Step',
        },
      };
      const conditions = this.workflowAction.payload.conditions || [];
      this.$emit('change', {
        ...this.workflowAction,
        payload: { conditions: [...conditions, newRule] },
      });
    },
    removeStepLogicRule(stepId) {
      const conditions = this.workflowAction.payload.conditions.filter(
        (el) => el.meta.entityId !== stepId,
      );
      this.$emit('change', { ...this.workflowAction, payload: { conditions } });
    },
    updateStepLogicRule(logicRule) {
      const logicRuleIdx = this.workflowAction.payload.conditions.findIndex(
        (el) => el.id === logicRule.id,
      );
      const conditions = [...this.workflowAction.payload.conditions];
      conditions[logicRuleIdx] = logicRule;
      this.$emit('change', { ...this.workflowAction, payload: { conditions } });
    },
  },
};
</script>
<style lang="scss">
.workflow-auto-step-selection {
  &__step-logic {
    border: 1px solid $grey-5-mayday;
    border-radius: 8px;
    padding: 8px;
    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &__label {
        padding: 4px 14px 4px 8px;
        background-color: white;
        border: 1px solid $grey-2-mayday;
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        &__icon {
          border: 1px solid $grey-2-mayday;
          border-radius: 2px;
          height: 28px;
          width: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          &__img {
            height: 16px;
            width: auto;
          }
        }
        &__step {
          align-self: center;
          font-size: 16px;
          font-weight: bold;
          color: black;
        }
      }
      &__trash:hover {
        color: $red-mayday;
        cursor: pointer;
      }
    }
  }

  &__condition {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
  }
}
</style>
