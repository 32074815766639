<template>
  <div :class="base">
    <el-table
      :class="`${base}__table`"
      v-loading="loader.table"
      header-row-class-name="thead-light"
      row-key="field"
      ref="multipleTable"
      :data="fields || []"
      @selection-change="onSelectionChange"
    >
      <el-table-column type="selection" align="center" min-width="120px">
      </el-table-column>
      <el-table-column label="Model" prop="name" width="120" sortable>
        <template v-slot="{ row }">
          <badge rounded type="primary">{{ row.model }}</badge>
        </template>
      </el-table-column>
      <el-table-column label="Attribut" prop="name" sortable>
        <template v-slot="{ row }">
          <b>{{ row.label }}</b>
        </template>
      </el-table-column>
    </el-table>
    <div :class="`${base}__cta`">
      <el-button
        type="primary"
        plain
        size="small"
        :loading="loader.submit"
        @click="submit"
      >
        {{ $t('settings.integration.menu.import-cta') }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'integration-settings-attributes',
  props: {
    integration: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      base: 'integration-settings-attributes',
      companyAttributes: [],
      fields: null,
      selection: [],
      loader: {
        table: false,
        submit: false,
      },
      loadingMessage: null,
      defaultSort: {
        prop: 'label',
        order: 'ascending',
      },
    };
  },
  async created() {
    this.loader.table = true;
    [this.companyAttributes, this.fields] =
      await this.initIntegrationAttributes(this.integration);
    this.toggleRowSelectionAtCreate();
    this.loader.table = false;
  },
  methods: {
    toggleRowSelectionAtCreate() {
      if (!this.companyAttributes || !this.companyAttributes.length) return;
      this.$nextTick(() => {
        this.companyAttributes.forEach((row) => {
          const el = this.fields.find(
            ({ field }) => String(field) === row.field,
          );
          if (el) this.$refs.multipleTable.toggleRowSelection(el);
        });
      });
    },
    onSelectionChange(selection) {
      this.selection = selection;
    },
    async submit() {
      this.loader.submit = true;
      const attrs = await this.setCompanyAttributes({
        integration: this.integration,
        companyAttributes: this.selection,
      });
      this.loader.submit = false;
      const type = attrs ? 'success' : 'error';
      this.loadingMessage = this.$message({
        message: this.$t(`settings.integration.menu.import-${type}`),
        type,
      });
    },
    ...mapActions(['initIntegrationAttributes', 'setCompanyAttributes']),
  },
};
</script>

<style lang="scss" scoped>
.integration-settings-attributes {
  &__table {
    :deep() .el-table__body-wrapper {
      max-height: 50vh;
      overflow: auto;
      overflow-x: hidden;
    }
  }

  &__cta {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
