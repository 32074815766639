import { render, staticRenderFns } from "./SelfcareWidget.vue?vue&type=template&id=61176b81&scoped=true&"
import script from "./SelfcareWidget.vue?vue&type=script&lang=js&"
export * from "./SelfcareWidget.vue?vue&type=script&lang=js&"
import style0 from "./SelfcareWidget.vue?vue&type=style&index=0&id=61176b81&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61176b81",
  null
  
)

export default component.exports