<template>
  <div>
    <el-switch
      v-model="switchValue"
      @change="newValue"
      size="mini"
      class="pl-2"
    ></el-switch>
  </div>
</template>

<script>
export default {
  name: 'BooleanInput',
  data() {
    return {
      switchValue: true,
    };
  },
  props: {
    element: Object,
    elementValueDisplay: String,
    isEmpty: Boolean,
  },
  methods: {
    newValue() {
      this.$emit('new-element', this.switchValue.toString());
    },
  },
  watch: {
    elementValueDisplay: {
      handler(newVal) {
        this.switchValue = newVal === 'true';
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
