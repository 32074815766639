it('', () => {
  expect(true).toBeTruthy();
});

// 'use strict';

// import DateRange from './DateRange';
// import ElementUI from 'element-ui';
// import locale from 'element-ui/lib/locale/lang/fr';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { fad } from '@fortawesome/pro-duotone-svg-icons';
// import { mount, createLocalVue, config, createWrapper } from '@vue/test-utils';
// import flushPromises from 'flush-promises';

// const filter = {
//   label: 'creationDate',
//   isRemote: false,
//   type: 'Date',
//   key: 'creationDate',
//   field: 'value',
//   filters: null,
//   value: null,
//   values: null,
//   __typename: 'GenericFilter',
// };

// let wrapper;
// let localVue;

// const getYesterdayButton = ($el) => {
//   const dayNumber = `${new Date().getDate() - 1}`;
//   const today = $el.querySelector('.today');
//   if (
//     today.previousSibling &&
//     today.previousSibling.textContent.includes(dayNumber)
//   ) {
//     return today.previousSibling;
//   }
//   const previousLineAvailableDays =
//     today.parentNode.previousSibling.querySelectorAll('td');

//   return previousLineAvailableDays[previousLineAvailableDays.length - 1];
// };

// // const getTomorrowButton = ($el) => {
// //   const dayNumber = `${new Date().getDate() + 1}`;
// //   const today = $el.querySelector('.today');

// //   if (today.nextSibling && today.nextSibling.textContent.includes(dayNumber)) {
// //     return today.nextSibling;
// //   }
// //   const nextLineAvailableDays =
// //     today.parentNode.nextSibling.querySelectorAll('td');

// //   return nextLineAvailableDays[0];
// // };

// describe('Filters/Filter/RangeValue/DateRange', () => {
//   beforeEach(() => {
//     const div = document.createElement('div');
//     div.id = 'root';
//     document.body.appendChild(div);

//     localVue = createLocalVue();
//     localVue.use(ElementUI, {
//       locale,
//     });
//     library.add(fad);
//     localVue.component('font-awesome-icon', FontAwesomeIcon);
//     config.mocks.$t = (key) => key;

//     wrapper = mount(DateRange, {
//       localVue,

//       attachTo: '#root',

//       propsData: {
//         filter,
//         value: ['', ''],
//         shortcutValues: {},
//       },
//     });
//     expect(wrapper.is(DateRange)).toBe(true);
//   });

//   // it('should prevent user from choosing any inappropriate date', async () => {
//   //   const pickers = wrapper.findAllComponents({ name: 'date' });
//   //   const firstDatePicker = pickers.at(0).getComponent({ ref: 'datePicker' });
//   //   const secondDatePicker = pickers.at(1).getComponent({ ref: 'datePicker' });
//   //   expect(pickers).toHaveLength(2);
//   //   await firstDatePicker.trigger('click');
//   //   let tomorrowButton = getTomorrowButton(firstDatePicker.vm.picker.$el);
//   //   const todayDateNumber = new Date().getDate();
//   //   expect(tomorrowButton.textContent).toContain(`${todayDateNumber + 1}`);
//   //   expect(tomorrowButton.classList).toContain('disabled');
//   //   let yesterdayButton = getYesterdayButton(firstDatePicker.vm.picker.$el);
//   //   await createWrapper(yesterdayButton).trigger('click');
//   //   await secondDatePicker.trigger('click');
//   //   await flushPromises();
//   //   tomorrowButton = getTomorrowButton(secondDatePicker.vm.picker.$el);
//   //   expect(tomorrowButton.textContent).toContain(`${todayDateNumber + 1}`);
//   //   expect(tomorrowButton.classList).toContain('disabled');
//   //   let todayButton = secondDatePicker.vm.picker.$el.querySelector('.today');
//   //   expect(todayButton.classList).toContain('disabled');
//   //   yesterdayButton = getYesterdayButton(secondDatePicker.vm.picker.$el);
//   //   expect(yesterdayButton.textContent).toContain(`${todayDateNumber - 1}`);
//   //   expect(yesterdayButton.classList).toContain('available');
//   // });

//   it('should emit change when range is chosen', async () => {
//     const pickers = wrapper.findAllComponents({ name: 'date' });
//     const firstDatePicker = pickers.at(0).getComponent({ ref: 'datePicker' });
//     const secondDatePicker = pickers.at(1).getComponent({ ref: 'datePicker' });

//     await firstDatePicker.trigger('click');
//     const yesterdayButton = getYesterdayButton(firstDatePicker.vm.picker.$el);
//     await createWrapper(yesterdayButton).trigger('click');
//     await flushPromises();
//     await secondDatePicker.trigger('click');
//     const todayButton = secondDatePicker.vm.picker.$el.querySelector('.today');
//     await createWrapper(todayButton).trigger('click');
//     await flushPromises();

//     // [ YesterdayDate, '' ]
//     expect(wrapper.vm.value).toStrictEqual([
//       `${new Date().getUTCFullYear()}-${(
//         '0' +
//         (new Date().getMonth() + 1)
//       ).slice(-2)}-${('0' + (new Date().getDate() - 1)).slice(-2)}`,
//       // `${new Date().getUTCFullYear()}-${(
//       //   '0' +
//       //   (new Date().getMonth() + 1)
//       // ).slice(-2)}-${('0' + new Date().getDate()).slice(-2)}`,
//       '',
//     ]);
//   });
// });
