<template>
  <div class="main-content bg-mesh-gradient" id="main">
    <div class="body-content">
      <!-- Header -->
      <div class="header pt-5 pb-4 pt-lg-5 pb-lg-4">
        <div class="container">
          <div class="header-body text-center mb-8">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 col-sm-9">
                <h1
                  class="text-white title"
                  v-if="$router.currentRoute.path === '/login'"
                >
                  {{ $t('landing.welcome.login') }}
                </h1>
                <h1
                  class="text-white title"
                  v-else-if="
                    ['/register', '/user/invite/'].includes(
                      $router.currentRoute.path,
                    )
                  "
                >
                  {{ $t('landing.welcome.register') }}
                </h1>
                <div
                  class="row mb-6"
                  v-else-if="
                    ['/permissions/validated', 'error'].includes(
                      $router.currentRoute.path,
                    )
                  "
                ></div>
                <h1 class="text-white title" v-else>
                  {{
                    errorFromRegister
                      ? $t('landing.welcome.register')
                      : $t('landing.welcome.reset')
                  }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container mt--8 pb-0">
        <div class="row justify-content-center">
          <img src="~assets/logos/mayday.svg" alt="Mayday logo" class="logo" />
        </div>
      </div>

      <!-- Page content -->
      <div class="container mt-3 pb-0">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-6 col-md-7 col-sm-9">
            <slide-y-up-transition mode="out-in" origin="center top">
              <router-view></router-view>
            </slide-y-up-transition>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <footer class="footer" style="line-height: 1.15 !important">
      <div class="container">
        <div
          class="row align-items-center justify-content-between py-2 mt-6 mt-md-0"
        >
          <div class="col-sm-12 col-md-auto text-center">
            <div class="copyright nav-footer text-white">
              <p>&copy; {{ year }} {{ $t('landing.footer.copyright') }}</p>
            </div>
          </div>
          <div class="col-sm-12 col-md-auto justify-content-center d-flex">
            <ul class="nav nav-footer justify-content-lg-end d-md-flex">
              <li class="nav-item">
                <a
                  href="https://www.mayday.fr"
                  class="nav-link text-white"
                  target="_blank"
                  >{{ $t('landing.footer.website') }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://www.mayday.fr/legal/politique-de-confidentialite"
                  class="nav-link text-white"
                  target="_blank"
                  >{{ $t('landing.footer.privacy-policy') }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://www.mayday.fr/legal/conditions-generales"
                  class="nav-link text-white"
                  target="_blank"
                  >{{ $t('landing.footer.tcu') }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { SlideYUpTransition } from 'vue2-transitions';

export default {
  name: 'auth-layout',
  components: {
    SlideYUpTransition,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
      langs: ['fr', 'en'],
    };
  },
  computed: {
    errorFromRegister() {
      const { message } = this.$route.query;
      return message === 'INVALID_TOKEN';
    },
  },
  methods: {
    updateAppLanguage(lang) {
      this.$i18n.locale = lang;
      this.storeUpdateLanguage(lang);
    },
    ...mapActions({
      storeUpdateLanguage: 'updateAppLanguage',
    }),
  },
};
</script>

<style scoped>
.bg-mesh-gradient {
  height: 100%;
  background-image: url('~assets/backgrounds/auth-background.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  display: contents;
}

.copyright p {
  margin-bottom: 0;
}

.footer .nav .nav-item .nav-link {
  color: white !important;
  font-size: 1rem !important;
  cursor: pointer;
}

.title {
  font-size: 2rem;
  line-height: 1.2;
}

.logo {
  width: 64px;
  z-index: 1000;
}

.body-content {
  zoom: 90%;
}
</style>
