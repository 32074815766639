<template>
  <BaseFilter :filter="filter" @reset="$emit('reset', $event)">
    <div class="shortcuts-container d-flex justify-content-between">
      <el-button
        v-for="([name, value], idx) in Object.entries(shortcutValues)"
        :class="computeShortcutClass(value)"
        :key="`daterange-shortcut-${name}-${idx}`"
        size="mini"
        plain
        round
        @click="() => handleShortcutClick(value)"
        >{{ $t(`filters.date-filter.shortcut.${name}`) }}</el-button
      >
      <el-button
        :class="computeShortcutClass(null, true)"
        size="mini"
        plain
        round
        disabled
        >{{ $t('filters.date-filter.shortcut.custom') }}</el-button
      >
    </div>
    <DateFilter
      :filter="filter"
      :value="filterValue[0]"
      :disabled-date="disabledDate(0)"
      inhibit-title
      @change="(value) => handleDateFilterChange(0, value)"
    >
      <template v-slot:label>
        <span class="range-field-label">{{
          $t('filters.date-filter.begin-label')
        }}</span>
      </template>
    </DateFilter>
    <hr class="range-underline" />
    <DateFilter
      :filter="filter"
      :value="filterValue[1]"
      :disabled-date="disabledDate(1)"
      inhibit-title
      @change="(value) => handleDateFilterChange(1, value)"
    >
      <template v-slot:label>
        <span class="range-field-label">{{
          $t('filters.date-filter.end-label')
        }}</span>
      </template>
    </DateFilter>
  </BaseFilter>
</template>

<script>
import filterFieldMixin from '../../../../mixins/FilterFieldMixin';
import DateFilter from '../SingleValue/Date';

export default {
  name: 'date-range',

  mixins: [filterFieldMixin],

  props: {
    shortcutValues: {
      type: Object,
      required: true,
    },
  },

  components: { DateFilter },

  computed: {
    isValueCustom() {
      return Object.values(this.shortcutValues).every(
        (value) => !this.isShortcutActive(value),
      );
    },
  },

  methods: {
    handleDateFilterChange(idx, value) {
      this.filterValue.splice(idx, 1, value);
      this.$emit('change', this.filterValue);
    },
    disabledDate(index) {
      return (date) => {
        const {
          filterValue: [minDate, maxDate],
        } = this;
        const yesterday = new Date().setDate(new Date().getDate() - 1);
        const isAfterNow = date.getTime() > yesterday;

        if (index === 0) {
          const maxDateDate = new Date(maxDate);
          return isAfterNow || date.getTime() > maxDateDate;
        }
        if (index === 1) {
          const minDateDate = new Date(minDate);
          const dayBeforeMinDate = minDateDate.setDate(
            minDateDate.getDate() - 1,
          );
          return isAfterNow || date.getTime() < dayBeforeMinDate;
        }
        return false;
      };
    },
    handleShortcutClick(value) {
      this.$emit('change', Array.from(value));
    },
    isShortcutActive(value) {
      return (
        value[0] === this.filterValue[0] && value[1] === this.filterValue[1]
      );
    },
    computeShortcutClass(value, isCustom = false) {
      const isActive = isCustom
        ? this.isValueCustom
        : this.isShortcutActive(value);
      return isActive ? 'is-active' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.range-field-label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: $grey-9-mayday;
}

.range-underline {
  height: 1px;
  background-color: $grey-2-mayday;
  border: none;
  margin: 10px;
}

.el-button {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: $grey-5-mayday;
  border-color: $grey-5-mayday;
  background-color: $grey-1-mayday;
  margin-left: 0px;

  &.is-active {
    border-color: $blue-mayday;
    color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.1);
  }
}
</style>
