<template>
  <div class="preferences">
    <div
      class="preference"
      v-for="preference in preferences"
      :key="preference.type"
    >
      <IntegrationSettingsPreferenceOption
        @switched="handleSwitch"
        @update-preference-value="handleUpdate"
        :preference="preference"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import IntegrationSettingsPreferenceOption from './IntegrationSettingsPreferenceOption.vue';
export default {
  name: 'integration-settings-preference',
  components: { IntegrationSettingsPreferenceOption },
  props: {
    preferencesAvailable: {
      type: Object,
      required: true,
    },
  },
  computed: {
    preferences() {
      let preferences = [];
      Object.keys(this.preferencesAvailable).forEach((type) => {
        preferences.push({
          type,
          ...this.preferencesAvailable[type],
          ...this.companyPreferences.find((o) => o.type === type),
        });
      });
      return preferences;
    },
    ...mapState(['companyPreferences']),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions([
      'addCompanyPreferences',
      'deleteCompanyPreferences',
      'updateCompanyPreferencesValue',
    ]),
    handleSwitch({ type, value, ruleValue }) {
      return this.updatePreferences(type, value, ruleValue);
    },
    handleUpdate({ type, ruleValue }) {
      return this.updatePreferenceValue(type, ruleValue);
    },
    async updatePreferences(preferenceType, e, ruleValue = 0) {
      if (e) {
        this.addCompanyPreferences({
          preferenceType,
          preferenceValue: String(ruleValue),
        });
      } else {
        const preference = this.companyPreferences.find(
          ({ type }) => type === preferenceType,
        );
        await this.deleteCompanyPreferences({
          preferenceIds: [preference.id],
        });
      }
    },
    async updatePreferenceValue(source, ruleValue) {
      const preference = this.companyPreferences.find(
        ({ type }) => type === source,
      );
      if (!preference)
        await this.addCompanyPreferences({
          preferenceType: source,
          preferenceValue: String(ruleValue),
        });
      else if (!ruleValue)
        await this.deleteCompanyPreferences({
          preferenceIds: [preference.id],
        });
      else
        await this.updateCompanyPreferencesValue({
          preferenceId: preference.id,
          preferenceValue: String(ruleValue),
        });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.step {
  display: flex;
  align-items: center;
  padding-bottom: 8px;

  .title {
    margin-left: 10px;
    color: black;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
