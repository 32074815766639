<template>
  <div>
    <div v-for="item in element.items" :key="item.info.lang">
      <settings-dropdown>
        <template slot="title">
          <div class="item-header d-flex flex-row align-items-center">
            <div class="d-flex align-items-center item-title-wrapper">
              <div class="item-title">
                {{ $t(`knowledge.languages.${item.info.lang}`) }}
              </div>
              <el-badge
                v-if="item.info.default"
                class="ml-2 default-tag"
                type="primary"
                size="mini"
              >
                {{ $t('settings.general.kb-manage.default') }}
              </el-badge>
            </div>

            <div class="subtitles lang-tag">{{ item.info.lang }}</div>
          </div>
        </template>
        <template slot="dropdown-content"
          ><div class="item-body">
            <PublicKnowledgeElement
              v-for="el in item"
              :key="el.label"
              :element="el"
              @new-operation="$emit('new-operation', $event)"
            /></div
        ></template>
      </settings-dropdown>
    </div>
  </div>
</template>

<script>
import PublicKnowledgeElement from '../PublicKnowledgeElement.vue';
import SettingsDropdown from '@/components/Settings/SettingsDropdown';

export default {
  name: 'collapse-input',
  components: {
    PublicKnowledgeElement,
    SettingsDropdown,
  },
  data() {
    return {
      currentItem: '',
    };
  },
  props: {
    element: Object,
  },
};
</script>

<style lang="scss" scoped>
.item-header {
  width: 100%;
  justify-content: space-between;
}
.item-icon-wrapper {
  width: 24px;
  height: 24px;
  background-color: $grey-1-mayday;
  border-radius: 4px;
}
.item-icon {
  color: $grey-6-mayday;
}

.item-title-wrapper {
  width: 50%;
}

.content-tag {
  width: 30%;
}

.lang-tag {
  width: 5%;
}

.subtitles {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.item-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: capitalize;
}

.item-body {
  padding: 10px;
  background-color: $grey-1-mayday;
}
:deep() .element:first-child {
  margin-bottom: 10px;
}
.item-actions {
  width: 5%;
  cursor: pointer;
  color: $grey-5-mayday;
}

:deep() .el-collapse-item__header {
  border: none;
  height: 40px;
}

:deep() .el-collapse {
  border: none;
}

:deep() .el-collapse-item__wrap {
  border: none;
}

.default-tag {
  background-color: rgba($blue-mayday, 0.1);
  border: 1px solid $blue-mayday;
  box-sizing: border-box;
  border-radius: 2px;
  color: $blue-mayday;
  font-weight: 700;
  font-size: 10px;
  line-height: 129.8%;
  text-align: center;
  letter-spacing: 0.5px;
  padding: 2px 8px;
}

:deep() .el-popper {
  padding: 0px !important;
}

.action-icon {
  width: 14px;
  height: 14px;
}

.dropdown-item {
  cursor: pointer;
}
:deep() .dropdown-container {
  padding: 0px;
}
</style>
