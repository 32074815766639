<template>
  <div>
    <div class="items-wrapper">
      <div
        class="settings-menu-list mb-4"
        v-for="item in data"
        :key="item.title"
      >
        <div class="flex-column mb-2 item-title">
          <h4 class="text-muted settings-menu-list-title">
            {{ $t(item.title) }}
          </h4>
        </div>
        <div id="menu-list d-flex">
          <ul style="padding-left: 1px">
            <li
              v-for="subItem in item.subItem"
              :key="subItem.title"
              class="settings-menu-list-item"
            >
              <router-link
                :to="{
                  name: `${knowledgeType}-${subItem.name}`,
                  hash: subItem.hash,
                }"
              >
                <font-awesome-icon
                  class="subitem-icon col-3"
                  :icon="subItem.icon"
                ></font-awesome-icon>
                <span class="col-9">
                  <small>{{ $t(subItem.title) }}</small>
                </span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'knowledge-settings-menu',
  props: {
    data: {
      type: Array,
      required: true,
    },
    knowledgeType: {
      // = public or private
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-menu-list {
  color: $grey-7-mayday;
  ul {
    list-style-type: none;
  }
}
.settings-menu-list-title {
  text-transform: uppercase;
  left: -15px;
  top: -2px;
}

.subitem-icon {
  padding: 0;
  margin: 0;
  width: 15px;
  height: 15px;
}
a {
  color: inherit;
  display: block;
  padding-left: 8px;
  padding-top: 3px;
}

.settings-menu-list-item {
  border-left: 2px solid $grey-2-mayday;
  .active {
    display: block;
    border-left: 2px solid $blue-mayday;
    padding-left: 8px;
    color: $blue-mayday;
    margin-left: -2px;
  }
}

.settings-menu-list-item:hover {
  border-left: 2px solid $grey-5-mayday;
}

.subitem-icon {
  color: $grey-5-mayday;
}

.items-wrapper {
  position: fixed;
  top: 96px;
}
</style>
