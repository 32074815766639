<template>
  <BaseFilter
    :filter="filter"
    :inhibit-title="inhibitTitle"
    @reset="$emit('reset', $event)"
  >
    <div class="date-filter-field-wrapper" @click="redirectClick">
      <slot name="label"></slot>
      <div class="right-block">
        <el-date-picker
          ref="datePicker"
          :value="filterValue"
          :placeholder="$t('filters.date-filter.placeholder')"
          :picker-options="{ disabledDate }"
          prefix-icon=""
          :clearable="false"
          v-bind="config"
          @input="$emit('change', $event)"
        >
        </el-date-picker>
        <font-awesome-icon :icon="['fad', 'calendar-week']" class="date-icon" />
      </div>
    </div>
  </BaseFilter>
</template>

<script>
import filterFieldMixin from '../../../../mixins/FilterFieldMixin';
export default {
  name: 'date',

  mixins: [filterFieldMixin],

  props: {
    inhibitTitle: {
      type: Boolean,
      default: false,
    },
    disabledDate: {
      type: Function,
      default: () => false,
    },
  },

  computed: {
    config() {
      switch (this.filter.type.toLowerCase()) {
        case 'timerange':
          return {
            type: 'datetime',
            'value-format': 'yyyy-MM-ddTHH:mm:ss',
          };
        case 'daterage':
        default:
          return {
            type: 'date',
            'value-format': 'yyyy-MM-dd',
            format: 'dd/MM/yyyy',
          };
      }
    },
  },

  methods: {
    redirectClick() {
      if (!this.$refs.datePicker) {
        return;
      }
      this.$refs.datePicker.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.date-filter-field-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .el-date-editor {
    :deep() .el-input__inner {
      border: none;
      cursor: pointer;
      text-align: right;
    }
    :deep() .el-input__prefix {
      display: none;
    }
  }

  .date-icon {
    margin-left: 12px;
    height: 16px;
  }
}
</style>
