<template>
  <div class="update-user-label-modal">
    <modal :show.sync="localDisplay">
      <div class="row d-flex mt-5 justify-content-center">
        <div class="col-11 text-center">
          <modal-header
            :style-name="{ backgroundColor: '#0081F91A' }"
            class="mb-3"
          >
            <div slot="icon" class="d-inline-flex">
              <font-awesome-icon
                class="icon blue-mayday"
                :icon="['fad', 'globe']"
              />
            </div>
            <div class="mt-3">
              <div>
                <p class="mb-0 font-weight-bold">
                  {{
                    isMonolingual
                      ? $t(
                          'public-knowledge.configuration.items.language.add-language-modal.explanation-set-multilingual',
                        )
                      : $t(
                          'public-knowledge.configuration.items.language.add-language-modal.explanation',
                        )
                  }}
                </p>
              </div>
            </div>
          </modal-header>
          <div class="flex-column d-flex justify-content-center subcontent">
            <p class="my-3">
              {{
                isMonolingual
                  ? $t(
                      'public-knowledge.configuration.items.language.add-language-modal.action-set-multilingual',
                    )
                  : $t(
                      'public-knowledge.configuration.items.language.add-language-modal.action',
                    )
              }}
            </p>
            <div class="mb-4 text-center">
              <el-select v-model="selectedValue" class="col-12 label-select">
                <el-option
                  v-for="lang in filteredOptions"
                  :key="lang"
                  :value="lang"
                  :label="$t(`knowledge.languages.${lang}`)"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div class="row"></div>
      <template slot="footer">
        <base-button type="danger" size="sm" @click="$emit('close')">{{
          $t('components.modals.update-user-label.cancel-button')
        }}</base-button>
        <base-button
          type="primary"
          outline
          size="sm"
          @click="handleAddLanguage"
        >
          {{ $t('components.modals.update-user-label.confirm-button') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import ModalHeader from '@/components/Modals/ModalHeader';
import { langOptions } from '@/utils/langOptions.js';

export default {
  components: {
    Modal,
    ModalHeader,
  },
  props: {
    display: {
      type: Boolean,
      required: true,
    },
    isMonolingual: {
      type: Boolean,
      default: false,
    },
    existingLanguages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localDisplay: this.display,
      selectedValue: '',
      options: langOptions,
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter((n) => !this.existingLanguages.includes(n));
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
  methods: {
    handleAddLanguage() {
      const event = this.isMonolingual ? 'set-multilingual' : 'new-language';
      this.$emit(event, this.selectedValue);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.blue-mayday {
  color: $blue-mayday;
}

.icon {
  width: 24px;
  height: 24px;
}

.subcontent {
  border: 1px solid $blue-mayday;
  border-radius: 2px;
}
</style>
