<template>
  <div :class="base">
    <WorkflowLogicRuleKey
      :class="`${base}__key`"
      :model-value="editableRule.key"
      :options="keyOptions"
      @update:modelValue="(evt) => setValue('key', evt)"
    />
    <WorkflowLogicRuleOperator
      :class="`${base}__operator`"
      :model-value="editableRule.operator"
      @update:modelValue="(evt) => setValue('operator', evt)"
    />
    <WorkflowLogicRuleValue
      :class="`${base}__value`"
      :model-value="editableRule.value"
      @update:modelValue="(evt) => setValue('value', evt)"
    />
    <font-awesome-icon
      :class="`${base}__remove`"
      :icon="['fal', 'times']"
      @click="$emit('remove')"
    />
  </div>
</template>
<script>
import WorkflowLogicRuleKey from './WorkflowLogicRuleKey.vue';
import WorkflowLogicRuleOperator from './WorkflowLogicRuleOperator.vue';
import WorkflowLogicRuleValue from './WorkflowLogicRuleValue.vue';

export default {
  name: 'workflow-logic-rule',
  components: {
    WorkflowLogicRuleKey,
    WorkflowLogicRuleOperator,
    WorkflowLogicRuleValue,
  },
  props: {
    rule: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    editableRule: {
      get() {
        return this.rule;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
  },
  data() {
    return {
      base: 'workflow-logic-rule',
      keyOptions: null,
    };
  },
  methods: {
    setValue(key, value) {
      this.editableRule = Object.assign({}, this.editableRule, {
        [key]: value,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.workflow-logic-rule {
  display: flex;
  flex-direction: row;

  &__key {
    flex: 8;
  }

  &__operator {
    flex: 6;
  }

  &__value {
    flex: 6;
  }

  &__remove {
    flex: 1;
    align-self: center;
    &:hover {
      color: $red-mayday;
      cursor: pointer;
    }
  }
}
</style>
