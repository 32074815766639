export const ruleOperators = [
  {
    key: 'category.basic',
    options: [
      {
        value: 'is',
        key: 'operator.is',
      },
      {
        value: 'is_not',
        key: 'operator.is_not',
      },
      {
        value: 'includes',
        key: 'operator.includes',
      },
      {
        value: 'not_includes',
        key: 'operator.not_includes',
      },
      {
        value: 'gt',
        key: 'operator.gt',
      },
      {
        value: 'gte',
        key: 'operator.gte',
      },
      {
        value: 'lt',
        key: 'operator.lt',
      },
      {
        value: 'lte',
        key: 'operator.lte',
      },
    ],
  },
  {
    key: 'category.array',
    options: [
      {
        value: 'array_length_is',
        key: 'operator.array_length_is',
      },
      {
        value: 'array_length_gt',
        key: 'operator.array_length_gt',
      },
      //   {
      //     value: 'array_length_gte',
      //     key: 'operator.array_length_gte',
      //   },
      {
        value: 'array_length_lt',
        key: 'operator.array_length_lt',
      },
      //   {
      //     value: 'array_length_lte',
      //     key: 'operator.array_length_lte',
      //   },
    ],
  },
  {
    key: 'category.advanced',
    options: [
      {
        value: 'regex',
        key: 'operator.regex',
      },
      {
        value: 'exists',
        key: 'operator.exists',
      },
    ],
  },
];
