'use strict';

import Checkboxes from './Checkboxes';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/fr';
import { mount, createLocalVue, config } from '@vue/test-utils';

const filter = {
  label: 'group',
  isRemote: false,
  type: 'Object',
  key: 'groupId',
  field: 'values',
  filters: null,
  value: null,
  values: [
    { label: 'Admin', key: null, value: '1', __typename: 'Value' },
    { label: 'Agent', key: null, value: '2', __typename: 'Value' },
  ],
  __typename: 'GenericFilter',
};

let wrapper;
let localVue;

describe('Filters/Filter/MultipleValues/Checkboxes', () => {
  beforeEach(() => {
    localVue = createLocalVue();
    localVue.use(ElementUI, {
      locale,
    });
    config.mocks.$t = (key) => key;

    wrapper = mount(Checkboxes, {
      localVue,

      propsData: {
        filter,
        value: [],
      },
    });
    expect(wrapper.is(Checkboxes)).toBe(true);
  });

  it('should display appropriate options to check', () => {
    const checkboxes = wrapper.findAllComponents({ name: 'el-checkbox' });
    expect(checkboxes).toHaveLength(filter.values.length);
    filter.values.forEach(({ label, value }, i) => {
      const checkbox = checkboxes.at(i).find('input[type="checkbox"]');
      const checkboxLabel = checkboxes
        .at(i)
        .find('.el-checkbox__label')
        .text();
      expect(checkbox.attributes('value')).toBe(value);
      expect(checkboxLabel).toBe(label);
    });
  });

  it('should emit change event with the correct value when a checkbox is checked', async () => {
    const checkbox = wrapper
      .findAllComponents({ name: 'el-checkbox' })
      .at(0)
      .find('input[type="checkbox"]');
    const value = filter.values[0].value;
    await checkbox.setChecked();
    expect(wrapper.emitted().change).toBeTruthy();
    expect(wrapper.emitted().change.length).toBe(1);
    expect(wrapper.emitted().change[0][0]).toStrictEqual([value]);
  });
});
