<template>
  <div>
    <div class="card bg-secondary shadow border-0" id="reset-box">
      <div class="card-body px-lg-5 py-lg-5">
        <div class="text-center text-muted mb-4">
          <h1 class="title">
            {{ $t('landing.form.reset-context-1') }}<br />
            {{ $t('landing.form.reset-context-2') }}
          </h1>
        </div>
        <base-alert type="danger" dismissible v-if="updateError.length > 0">
          <span class="alert-inner--text"
            ><strong> {{ updateError }} </strong></span
          >
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </base-alert>
        <base-alert type="success" dismissible v-if="updateSuccess.length > 0">
          <span class="alert-inner--text"
            ><strong> {{ updateSuccess }} </strong></span
          >
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </base-alert>

        <div role="form">
          <base-input
            ref="password"
            :label="$t('landing.form.password')"
            class="input-group-alternative mb-3"
            type="password"
            :placeholder="$t('landing.form.password-placeholder')"
            addon-left-icon="fa fa-key"
            :show-or-hide="true"
            :tooltip="true"
            :valid="isValid('password')"
            name="password"
            v-model="model.password"
          >
            <template v-slot:tooltip-content>
              <h4>{{ $t('landing.form.tooltip.password-title') }}</h4>
              <i
                class="fa fa-minus info-content-icon"
                :class="longEnoughClass"
              ></i>
              {{ $t('landing.form.tooltip.long-enough') }}<br />
              <i
                class="fa fa-font info-content-icon"
                :class="containLettersClass"
              ></i>
              {{ $t('landing.form.tooltip.contain-letters') }}<br />
              <i
                class="fa fa-hashtag info-content-icon"
                :class="containNumberClass"
              ></i>
              {{ $t('landing.form.tooltip.contain-numbers') }}<br />
              <i
                class="fa fa-at info-content-icon"
                :class="containSpecialClass"
              ></i
              >{{ $t('landing.form.tooltip.contain-specials') }}
            </template>
          </base-input>
          <base-input
            ref="repeatPassword"
            :label="$t('landing.form.confirm-password')"
            class="input-group-alternative mb-3"
            type="password"
            :placeholder="$t('landing.form.password-placeholder')"
            addon-left-icon="fa fa-key"
            :show-or-hide="true"
            :tooltip="true"
            name="repeatPassword"
            :valid="isValid('repeatPassword')"
            v-model="model.repeatPassword"
            @keyup.enter="enterClicked()"
          >
            <template v-slot:tooltip-content>
              <h5 class="my-2">{{ $t('landing.form.different-passwords') }}</h5>
            </template>
          </base-input>
          <div class="text-center">
            <base-button
              ref="submit"
              type="primary"
              @click.prevent="changePassword()"
              class="reset-button mt-5"
              :disabled="isButtonDisabled"
            >
              <div v-if="isResetLoading" role="status">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
              <span v-else>{{ $t('landing.reset-2') }}</span>
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex col-12 pt-lg-2 font-weight-bold text-white justify-content-center text-center"
      v-if="!tooManyAttempts"
    >
      <p class="my-0">
        {{ $t('landing.form.register-footer-1') }}<br />
        <router-link to="/login" class="text-underline dark-link">
          {{ $t('landing.form.register-footer-2') }}
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { required, sameAs } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import BaseAlert from '@/components/BaseAlert.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseInput from '@/components/BaseInput.vue';

const change_password = gql`
  mutation changePasswordMutation($token: String!, $password: String!) {
    changePassword(token: $token, password: $password)
  }
`;

export default {
  name: 'setnewpassword',
  components: {
    BaseAlert,
    BaseButton,
    BaseInput,
  },
  data() {
    return {
      model: {
        password: '',
        repeatPassword: '',
      },
      updateSuccess: '',
      updateError: '',
      isResetLoading: false,
    };
  },
  validations: {
    model: {
      password: {
        required,
        longEnough: (password) => {
          return password.length >= 8;
        },
        containUpperAndLowerLetters: (password) => {
          return /[a-z]/.test(password) && /[A-Z]/.test(password);
        },
        containNumber: (password) => {
          return /[0-9]/.test(password);
        },
        containSpecialCaracter: (password) => {
          return /\W|_/.test(password);
        },
      },
      repeatPassword: {
        sameAsPassword: sameAs('password'),
      },
    },
  },
  computed: {
    longEnoughClass() {
      return this.$v.model.password.longEnough ? 'has-success' : 'has-danger';
    },
    containLettersClass() {
      return this.$v.model.password.containUpperAndLowerLetters
        ? 'has-success'
        : 'has-danger';
    },
    containNumberClass() {
      return this.$v.model.password.containNumber
        ? 'has-success'
        : 'has-danger';
    },
    containSpecialClass() {
      return this.$v.model.password.containSpecialCaracter
        ? 'has-success'
        : 'has-danger';
    },
    isButtonDisabled() {
      return this.isResetLoading || this.$v.model.$invalid;
    },
    ...mapGetters(['tooManyAttempts']),
  },
  methods: {
    enterClicked() {
      if (!this.isButtonDisabled) {
        this.$refs.submit.$el.children[0].click();
      }
    },
    isValid(data) {
      if (this.model[data] === '') {
        return undefined;
      }
      return !this.$v.model[data].$invalid;
    },
    changePassword() {
      const password = this.model.password;
      const token = this.$route.params.token;
      this.$apollo
        .mutate({
          mutation: change_password,
          variables: {
            token,
            password,
          },
        })
        .then((response) => {
          const auth = response.data.changePassword;
          if (auth) {
            this.updateSuccess = this.$t('landing.form.reset-success');
            this.resetAuthAttempt();
            this.$router.push({ name: 'login', query: { origin: 'reset' } });
          } else {
            this.updateError = this.$t('landing.form.reset-fail');
          }
        });
    },
    ...mapActions(['resetAuthAttempt']),
  },
};
</script>

<style lang="scss" scoped>
#reset-box {
  margin-top: -50px;
  background-color: rgba(255, 255, 255, 0.82) !important;
  border-radius: 4px !important;
}

.title {
  line-height: 1.2 !important;
}

.dark-link {
  color: #00407c;
}

.dark-link:hover {
  text-decoration: none;
}

.has-success:after,
.has-danger:after {
  display: none;
}

.has-success {
  color: rgba($blue-mayday, 0.4);
}

.has-danger {
  color: rgba($red-mayday, 0.4);
}
</style>
