<template>
  <BaseFilter
    :filter="filter"
    :condition-value="conditionValue"
    :remote-value="remoteValue"
    :value="value"
    @reset="$emit('reset', $event)"
    @unselect-all="$emit('unselect-all')"
    @change-additional="$emit('change-additional', $event)"
  >
    <el-select
      v-model="filterValue"
      multiple
      filterable
      :placeholder="$t('generic.select')"
    >
      <el-option
        v-for="{ label, value, key } in displayFilters"
        :key="key"
        :label="label"
        :value="value"
      >
      </el-option>
    </el-select>
  </BaseFilter>
</template>

<script>
import filterFieldMixin from '../../../../mixins/FilterFieldMixin';
export default {
  name: 'multi-select',
  mixins: [filterFieldMixin],
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
  :deep() &__input {
    margin-left: 5px;
  }
}
</style>
