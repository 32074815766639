<template>
  <WorkflowInput
    :class="base"
    :placeholder="$t(`${pre}.placeholder`)"
    :value="inputValue"
    @change="value = $event"
  />
</template>
<script>
import WorkflowInput from '../../WorkflowInput.vue';

export default {
  name: 'workflow-logic-rule-key',
  components: {
    WorkflowInput,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.inputValue = this.value;
  },

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  data() {
    return {
      base: 'workflow-logic-rule-key',
      pre: 'knowledge.diagnostic-builder.side-bar.workflow.inputs.auto-step-selection.key',
      inputValue: '',
    };
  },
  watch: {
    value() {
      this.inputValue = this.value;
    },
  },
};
</script>
