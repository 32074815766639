<template>
  <!-- <TaskBoard
    :columns="getGroupedTasksByStatus"
    :modals="modals"
    :dragged-task-id="draggedTaskId"
    :focus-task="getFocusTask"
    :show-bulk-actions="showBulkActions"
    :selected-task-ids="selectedTaskIds"
    :animated-task-id="animatedTaskId"
    :archive-permission="archivePermission"
    :comment-permission="commentPermission"
    :update-permission="updatePermission"
    @handle-drag-and-drop="handleDragAndDrop"
    @handle-update-assignee="handleUpdateAssignee"
    @handle-update-status="handleUpdateStatus"
    @handle-is-archive="handleIsArchive"
    @handle-show-bulk-actions="showBulkActions = true"
    @add-upvote="addUpvote"
    @remove-upvote="removeUpvote"
    @open-focus-task="handleFocusTaskOpening"
    @close-assignee-modal="modals.assignee = false"
    @close-focus-modal="handleFocusTaskClosing"
    @update-selected-tasks="$emit('update-selected-tasks', $event)"
    @handle-new-comment="handleNewComment"
    @delete-comment="handleDeleteComment"
    @empty-filters="$emit('empty-filters')"
    @update-rating="handleUpdateRating"
    @delete-rating="handleDeleteRating"
    @fetch-next-page="$emit('fetch-next-page', $event)"
  /> -->
  <div
    v-loading="true"
    class="d-flex h-100 w-100 justify-content-center align-items-center full-screen"
  ></div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

// import TaskBoard from './TaskBoard.vue';

export default {
  name: 'task-board-wrapper',
  components: {
    // TaskBoard,
  },
  props: {
    selectedTaskIds: {
      type: Array,
      default: () => [],
    },
    archivePermission: {
      type: Boolean,
      default: false,
    },
    commentPermission: {
      type: Boolean,
      default: false,
    },
    updatePermission: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dragAndDropHandlers: {
        added: this.handleDrop,
      },
      modals: {
        assignee: false,
        focus: false,
      },
      draggedTaskId: null,
      showBulkActions: false,
      lastColumnId: null,
      animatedTaskId: null,
    };
  },
  async created() {
    await this.init();
  },
  mounted() {
    const { modalTaskId } = this.$route.query;
    const path = this.$route.path.replace('/tasks/board', '/hub');
    const stringQuery = modalTaskId ? `?taskId=${modalTaskId}` : '';
    window.location.href = `${window.location.origin}${path}${stringQuery}`;
  },
  methods: {
    async init() {
      this.isLoading = true;
      // This needs to be refactored hard
      this.lastColumnId =
        this.getOrderedCompanyTaskStatus[
          this.getOrderedCompanyTaskStatus.length - 1
        ].id;

      this.isLoading = false;
    },
    handleDragAndDrop(e) {
      const type = Object.keys(e.$event)[0];
      const eventHandler = this.dragAndDropHandlers[type];

      if (eventHandler) eventHandler(e);
    },
    handleDrop({
      $event: {
        added: { element },
      },
      companyTaskStatusId,
    }) {
      this.handleUpdateStatus({
        $event: companyTaskStatusId,
        taskId: element.id,
        oldStatusId: element.companyTaskStatusId,
      });
    },
    handleUpdateAssignee(e) {
      const { assignee, statusId } = e;
      let { taskId } = e;
      if (!taskId) {
        taskId = this.draggedTaskId;
      }
      this.setAssignee({ taskId, statusId, assignee });

      this.modals.assignee = false;
    },
    handleUpdateStatus(e) {
      const { $event, taskId, oldStatusId } = e;
      this.setCompanyTaskStatus({
        taskId,
        newCompanyTaskStatusId: $event,
        oldCompanyTaskStatusId: oldStatusId,
      });
    },
    handleFocusTaskOpening($event) {
      const { taskId } = $event;
      this.$router.replace({
        query: { ...this.$route.query, modalTaskId: taskId },
      });
    },
    handleFocusTaskClosing() {
      this.modals.focus = false;
      this.resetFocusTask();
      const newQuery = { ...this.$route.query };
      delete newQuery.modalTaskId;
      this.$router.replace({ query: newQuery });
    },
    handleIsArchive(e) {
      const { taskId, isArchive } = e;
      this.handleFocusTaskClosing();
      this.bulkSetIsArchive({ ids: [taskId], isArchive });
      this.$emit('update-selected-tasks', []);
    },
    addUpvote({ taskId, statusId }) {
      this.upvoteTask({ taskId, statusId });
    },
    removeUpvote({ taskId, statusId }) {
      this.removeUpvoteTask({ taskId, statusId });
    },
    async focusOnTask(routeQuery) {
      const { modalTaskId } = routeQuery;
      if (modalTaskId) {
        this.modals.focus = true;
        await this.setFocusTask({ taskId: modalTaskId });
      }
    },
    handleNewComment($event) {
      this.newTaskComment($event);
    },
    handleDeleteComment($event) {
      this.deleteTaskComment($event);
    },
    handleUpdateRating($event) {
      this.setTaskRating($event);
    },
    handleDeleteRating($event) {
      this.deleteTaskRating($event);
    },
    ...mapActions('taskModule', [
      'getAssignableUsers',
      'setFocusTask',
      'resetFocusTask',
      'setCompanyTaskStatus',
      'setAssignee',
      'bulkSetIsArchive',
      'upvoteTask',
      'removeUpvoteTask',
      'newTaskComment',
      'deleteTaskComment',
      'setTaskRating',
      'deleteTaskRating',
    ]),
    ...mapActions('adminModule', [
      'getCompanyUserLabelCategories',
      'getCustomRoles',
      'getContributors',
    ]),
    ...mapActions('webKnowledgeModule', ['getKnowledges']),
  },
  computed: {
    ...mapGetters('taskModule', [
      'getGroupedTasksByStatus',
      'getFocusTask',
      'getOrderedCompanyTaskStatus',
      'loadingFocusTask',
      'archiveLoading',
    ]),
  },
  watch: {
    $route: {
      async handler(newVal) {
        await this.focusOnTask(newVal.query);
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
