'use strict';

import Radio from './Radio';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/fr';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { mount, createLocalVue, config } from '@vue/test-utils';

const filter = {
  label: 'knowledge',
  isRemote: false,
  type: 'Object',
  key: 'knowledgeId',
  field: 'value',
  filters: null,
  value: null,
  values: [
    {
      label: 'Base de connaissance de test',
      key: null,
      value: '1',
      __typename: 'Value',
    },
  ],
  __typename: 'GenericFilter',
};

let wrapper;
let localVue;

describe('Filters/Filter/SingleValue/Radio', () => {
  beforeEach(() => {
    localVue = createLocalVue();
    library.add(fad);
    library.add(fal);
    localVue.component('font-awesome-icon', FontAwesomeIcon);
    localVue.use(ElementUI, {
      locale,
    });
    config.mocks.$t = (key) => key;

    wrapper = mount(Radio, {
      localVue,

      propsData: {
        filter,
        value: [],
      },
    });
    expect(wrapper.is(Radio)).toBe(true);
  });

  it('should display appropriate options to check', () => {
    const radios = wrapper.findAllComponents({ name: 'el-radio' });
    expect(radios).toHaveLength(filter.values.length);
    filter.values.forEach(({ label, value }, i) => {
      const radio = radios.at(i).find('input[type="radio"]');
      const radioLabel = radios.at(i).find('.temp').text();
      expect(radio.attributes('value')).toBe(value);
      expect(radioLabel).toBe(label);
    });
  });

  it('should emit change event with the correct value when a radio is checked', async () => {
    const checkbox = wrapper
      .findAllComponents({ name: 'el-radio' })
      .at(0)
      .find('input[type="radio"]');
    const value = filter.values[0].value;
    await checkbox.setChecked();
    expect(wrapper.emitted().change).toBeTruthy();
    expect(wrapper.emitted().change.length).toBe(1);
    expect(wrapper.emitted().change[0][0]).toStrictEqual(value);
  });
});
