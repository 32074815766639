<template>
  <div class="logout-view">
    <font-awesome-icon
      :icon="['fas', 'spinner-third']"
      class="logout-view-icon"
      spin
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Logout',
  mounted() {
    this.logout(true);
  },
  methods: {
    ...mapActions(['logout']),
  },
};
</script>

<style lang="scss" scoped>
.logout-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.logout-view-icon {
  font-size: 5rem;
  color: $blue-mayday;
}
</style>
