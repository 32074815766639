<template>
  <WorkflowSelect
    :class="base"
    :placeholder="$t(`${pre}.placeholder`)"
    :grouped-options="true"
    :options="operators"
    :value="value"
    @change="value = $event"
  />
</template>
<script>
import { ruleOperators } from './utils';
import WorkflowSelect from '../../WorkflowSelect.vue';

const operatorsPre = 'knowledge.diagnostic-builder.side-bar.workflow.rules';

export default {
  name: 'workflow-logic-rule-operator',
  components: { WorkflowSelect },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  mounted() {},
  data() {
    return {
      base: 'workflow-logic-rule-operator',
      pre: 'knowledge.diagnostic-builder.side-bar.workflow.inputs.auto-step-selection.operator',
      operators: Object.freeze(this.translateOperators(ruleOperators)),
    };
  },
  methods: {
    translateOperators(operators) {
      return operators.map((group) => ({
        ...group,
        label: this.$t(`${operatorsPre}.${group.key}`),
        options: group.options.map((item) => ({
          ...item,
          label: this.$t(`${operatorsPre}.${item.key}`),
        })),
      }));
    },
  },
};
</script>
