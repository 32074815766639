<template>
  <div>
    <div role="form">
      <base-input
        ref="email"
        :label="$t('landing.form.email')"
        class="input-group-alternative mb-0"
        placeholder="john.doe@mayday.fr"
        addon-left-icon="fa fa-envelope"
        :valid="isValid('email')"
        v-model="model.email"
        autocomplete="username" 
      ></base-input>
      <base-input
        ref="password"
        :label="$t('landing.form.password')"
        class="input-group-alternative mt-2 mb-0"
        placeholder="••••••••"
        type="password"
        addon-left-icon="fa fa-key"
        :show-or-hide="true"
        name="password"
        :valid="isValid('password')"
        v-model="model.password"
        @keyup.enter="enterClicked()"
      ></base-input>
      <div class="text-right">
        <router-link
          to="/reset"
          class="px-2 small-link"
          :class="{ highlight: maxAuthAttempts === 3 }"
        >
          <small>{{ $t('landing.forgotten-password') }}</small>
        </router-link>
      </div>
      <div class="text-center">
        <base-button
          ref="submit"
          type="primary"
          class="mb-2 mt-3"
          @click="triggerLogin()"
          :disabled="isButtonDisabled"
        >
          <div v-if="isLoginLoading" role="status">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
          <span v-else>{{ $t('landing.login') }}</span>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email as vEmail, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import BaseButton from '@/components/BaseButton.vue';
import BaseInput from '@/components/BaseInput.vue';

export default {
  name: 'login-form',
  components: {
    BaseButton,
    BaseInput,
  },
  props: {
    isLoginLoading: {
      required,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      model: {
        email: '',
        password: '',
      },
    };
  },
  validations() {
    const email = {
      required,
      ...(this.disableEmailValidation ? {} : { email: vEmail }),
    };
    return {
      model: {
        email: email,
        password: {
          required,
          minLength: minLength(6),
        },
      },
    };
  },
  computed: {
    isButtonDisabled() {
      return (
        this.isLoginLoading || this.$v.model.$invalid || this.tooManyAttempts
      );
    },
    disableEmailValidation() {
      return this.customConfig
        ? this.customConfig.disable_email_validation
        : false;
    },
    ...mapGetters([
      'tooManyAttempts',
      'warningAttempts',
      'maxAuthAttempts',
      'customConfig',
    ]),
  },
  methods: {
    triggerLogin() {
      this.$emit('submit', this.model);
    },
    enterClicked() {
      if (!this.isButtonDisabled) {
        this.$refs.submit.$el.children[0].click();
      }
    },
    isValid(data) {
      if (this.model[data] === '') {
        return undefined;
      }
      return !this.$v.model[data].$invalid;
    },
  },
};
</script>

<style lang="scss" scoped>
.small-link {
  color: #8898aa;
}

.small-link:hover {
  color: #0059ad;
}

.highlight {
  color: $blue-mayday;
}
</style>
