import BaseFilter from '../components/Filters/Filter/Base';

export default {
  components: {
    BaseFilter,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    value: {
      required: true,
    },
    conditionValue: {
      type: String,
      required: false,
    },
    remoteValue: {
      type: String,
      required: false,
    },
  },

  methods: {
    handleFilterChange($event) {
      this.$emit('change', $event);
    },
  },
  computed: {
    filterValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
        return value;
      },
    },
    displayFilters() {
      const reg = new RegExp(`${this.remoteValue}.*`, 'i');
      return this.filter.isRemote && this.remoteValue
        ? this.filter.values.filter((value) => {
            return reg.test(value.label);
          })
        : this.filter.values;
    },
    disabledValues() {
      return this.conditionValue === 'AND' && this.value.length
        ? this.filter.values
            .filter((filterValue) => {
              return this.value.includes('0')
                ? filterValue.value !== '0'
                : filterValue.value === '0';
            })
            .reduce((acc, filterValue) => {
              acc.add(filterValue.value);
              return acc;
            }, new Set())
        : new Set();
    },
  },
};
