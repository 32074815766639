'use strict';

import DateField from './Date';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/fr';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { mount, createLocalVue, config } from '@vue/test-utils';

const filter = {
  label: 'creationDate',
  isRemote: false,
  type: 'Date',
  key: 'creationDate',
  field: 'value',
  filters: null,
  value: null,
  values: null,
  __typename: 'GenericFilter',
};

let wrapper;
let localVue;

describe('Filters/Filter/SingleValue/Date', () => {
  beforeEach(() => {
    localVue = createLocalVue();
    localVue.use(ElementUI, {
      locale,
    });
    library.add(fad);
    localVue.component('font-awesome-icon', FontAwesomeIcon);
    config.mocks.$t = (key) => key;

    wrapper = mount(DateField, {
      localVue,

      propsData: {
        filter,
        value: '',
      },
    });
    expect(wrapper.is(DateField)).toBe(true);
  });

  it('should wrap DatePicker with the appropriate options', () => {
    const datePicker = wrapper.getComponent({ ref: 'datePicker' });
    expect(datePicker.exists()).toBe(true);
    const props = datePicker.props();
    expect(props.valueFormat).toBe('yyyy-MM-dd');
    expect(props.format).toBe('dd/MM/yyyy');
    expect(props.value).toBe('');
    expect(props.placeholder).toBe('filters.date-filter.placeholder');
  });

  it('should display appropriate icon after the field', () => {
    const icon = wrapper
      .getComponent({ name: 'font-awesome-icon' })
      .find('.date-icon');
    expect(icon.classes()).toContain('fa-calendar-week');
    expect(icon.attributes('data-prefix')).toBe('fad');
  });

  // it('should emit change when user pick any available date', async () => {
  //   await wrapper.find('.date-filter-field-wrapper').trigger('click');
  //   const { pickerVisible } = wrapper.getComponent({
  //     ref: 'datePicker',
  //   }).vm;
  //   expect(pickerVisible).toBe(false);
  //   const picker = wrapper.getComponent({
  //     ref: 'datePicker',
  //   }).vm.picker.$el;
  //   await createWrapper(picker.querySelector('.today')).trigger('click');
  //   expect(wrapper.emitted().change).toBeTruthy();
  //   expect(wrapper.emitted().change.length).toBe(1);
  //   // value is emitted in 'yyyy-MM-dd' format (for backend)
  //   expect(wrapper.emitted().change[0][0]).toStrictEqual(
  //     `${new Date().getUTCFullYear()}-${(
  //       '0' +
  //       (new Date().getMonth() + 1)
  //     ).slice(-2)}-${('0' + new Date().getDate()).slice(-2)}`,
  //   );
  // });
});
