<template>
  <div :class="[layout === 'col' ? 'flex-column justify-center' : 'flex-row']" class="d-flex align-items-center gap-1">
    <el-color-picker v-model="colorValue" @change="newValue" color-format="hex">
    </el-color-picker>
    <div class="placeholder-value">
      {{ colorValue }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorInput',
  props: {
    element: Object,
    elementValueDisplay: String,
    layout: {
      type: String,
      default: 'row',
    },
    isEmpty: Boolean,
  },
  data() {
    return {
      colorValue: '',
    };
  },
  mounted() {
    this.colorValue = this.elementValueDisplay;
  },
  methods: {
    newValue() {
      if (this.colorValue === null && this.element.fallback)
        return this.$emit('new-element', this.element.fallback);
      return this.$emit('new-element', this.colorValue);
    }
  },
  watch: {
    elementValueDisplay() {
      this.colorValue = this.elementValueDisplay;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-color-picker) {
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.el-color-picker__trigger) {
  border-radius: 50%;
  padding: 0;
  width: 30px;
  height: 30px;
}

:deep(.el-color-picker__color) {
  border-radius: 50%;
  border: none;
}

:deep(.el-color-picker__color-inner) {
  border-radius: 50%;
}

.placeholder-value {
  font-size: 12px;
  color: $grey-6-mayday;
}
</style>
